import React from 'react';
import { StyleSheet, View, TouchableOpacity } from 'react-native';
import { Image } from 'expo-image';
import { images } from '@theme';

const styles = StyleSheet.create({
  logo: {
    width: 96,
    height: 47,
    marginRight: 30,
  },
});

const stylesDark = StyleSheet.create({
  logo: {
    width: 87,
    height: 41,
    marginRight: 30,
  },
});


export function StackHeaderTitle({navigation}) {
  return <View><TouchableOpacity onPress={()=>{navigation.navigate('HomeTab')}}><Image source={images.logo} style={styles.logo} /></TouchableOpacity></View>;
}

export function StackHeaderTitleDark({navigation}) {
  return <View><TouchableOpacity onPress={()=>{navigation.navigate('HomeTab')}}><Image source={images.logoDark} style={stylesDark.logo} /></TouchableOpacity></View>;
}

