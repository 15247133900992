import React from 'react';
import { Text } from 'react-native';
import { colors } from '@theme';

interface MessageDisplayProps {
  readonly message: string;
}

export default function MessageDisplay({ message }: MessageDisplayProps) {
  return (
    <Text
      style={{
        color: 'white',
        backgroundColor: colors.smartTeal,
        fontSize: 12,
        marginBottom: 0,
        marginTop: 20,
        padding: 10,
        borderRadius: 6,
      }}>
      {message}
    </Text>
  );
}
