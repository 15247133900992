import { colors } from "@theme";
import { StyleSheet } from "react-native";
import { View } from "react-native";

export default function HorizontalLine() {
  return (
    <View
      style={{
        borderBottomColor: colors.ltPurple,
        borderBottomWidth: StyleSheet.hairlineWidth,
        width: '100%',
        marginTop: 64,
        marginBottom: 6,
        zIndex: -1,
      }}
    />
  );
}