import React, { useState } from 'react';
import { Text, View, ScrollView, StyleSheet, StatusBar } from 'react-native';
import { StackProps } from '@navigator/stack';
import { colors } from '@theme';
import { useAppModule } from '@modules/app.module';
import ArticleListItem from '@components/ArticleListItem';
import SongListItem from '@components/SongListItem';
import { TouchableOpacity } from 'react-native-gesture-handler';

const styles = StyleSheet.create({
  root: {
    width: '100%',
    height: '100%',
    backgroundColor: colors.lightGrayPurple,
    borderTopWidth: StyleSheet.hairlineWidth,
    borderColor: colors.ltPurple,
  },
  container: {
    width: '100%',
    maxWidth: 1200,
    padding: 22,
    paddingTop: 0,
    marginHorizontal: 'auto',
  },
  title: {
    fontSize: 24,
    color: colors.smartPurple,
    marginTop: 20,
  },
  wrapper: {},
  subtitle: {
    fontSize: 14,
    color: colors.smartPurple,
  },
  buttonTitle: {
    fontSize: 16,
    color: colors.white,
    textAlign: 'center',
  },
  button: {
    paddingVertical: 8,
    paddingHorizontal: 16,
    borderRadius: 5,
    marginTop: 20,
    marginBottom: 20,
    backgroundColor: colors.lightPurple,
  },
  circle: {
    width: 40,
    height: 40,
    borderRadius: 20,
    backgroundColor: colors.smartPink,
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 15,
    cursor: 'pointer',
  },
  circleTitle: {
    color: colors.white,
    textAlign: 'center',
    includeFontPadding: false,
    textTransform: 'uppercase',
    fontFamily: 'Nunito_900Black',
    fontSize: 20,
    fontWeight: '900',
    lineHeight: 10,
    marginTop: 8,
  },
  circleTitleSmall: {
    color: colors.white,
    textAlign: 'center',
    includeFontPadding: false,
    textTransform: 'uppercase',
    fontFamily: 'Nunito_900Black',
    fontSize: 12,
    fontWeight: '900',
    lineHeight: 10,
    marginTop: 8,
  },
  circleSubtitle: {
    color: colors.white,
    textAlign: 'center',
    includeFontPadding: false,
    textTransform: 'uppercase',
    fontFamily: 'Nunito_900Black',
    fontSize: 7,
    fontWeight: '900',
    lineHeight: 20,
  },
  notFound: {
    color: colors.smartPurple,
    fontSize: 14,
    textAlign: 'center',
    marginTop: 20,
  },
});

export default function Favorites({ navigation }: StackProps) {
  const { feed, user } = useAppModule();
  
  const songs = JSON.parse(JSON.stringify(feed?.songs || []));
  const articles = JSON.parse(JSON.stringify(feed?.articles || []));
  const favoriteSongs = JSON.parse(JSON.stringify(user?.user?.favoriteSongs || [])).map(song => song.id);
  const favoriteArticles = JSON.parse(JSON.stringify(user?.user?.favoriteArticles || [])).map(article => article.id);
  
  const colorsArray = [
    colors.smartPink,
    colors.smartOrange,
    colors.smartYellow,
    colors.smartTeal,
    colors.smartPurple,
    colors.ltPink,
    colors.ltOrange,
    colors.ltYellow,
    colors.ltTeal,
    colors.ltPurple,
  ];

  window.dataLayer.push({ event: 'page_load', page: 'Favorites'});

  return (
    <ScrollView style={styles.root}>
      <View style={styles.container}>
        <StatusBar barStyle="light-content" />

        {/* list favorite songs */}
        
        {songs.filter(
          song => favoriteSongs.includes(song.id)
        ).map((song, index) => (
          <SongListItem
            color={colors.smartPurple}
            subtitle={true}
            key={`${index}-song`}
            navigation={navigation}
            song={song}
          />
        ))}

        {/* list favorite articles */}
        {articles.filter(
          article => favoriteArticles.includes(article.id)
        ).map((article, index) => (
          <ArticleListItem
            subtitle={true}
            theme={'home'}
            key={`${index}-article`}
            navigation={navigation}
            article={article}
          />
        ))}

        {/* {level?.feed?.map((feedItem, jindex) => {
          const article = feedItem?.article;
          const song = feedItem?.song;
          const songaudio = song?.audio?.url;

          return (
            <React.Fragment key={jindex}>
              {article && (
                <ArticleListItem
                  subtitle={true}
                  theme={'home'}
                  key={`${jindex}-article`}
                  navigation={navigation}
                  article={article}
                />
              )}
              {songaudio && (
                <SongListItem
                  color={colors.smartPurple}
                  subtitle={true}
                  key={`${jindex}-song`}
                  navigation={navigation}
                  song={song}
                />
              )}
              {(!article && !songaudio) && <Text style={styles.notFound}>No content found</Text>}
            </React.Fragment>
          );
        })} */}
        {/* {(level?.feed.length === 0) && <Text style={styles.notFound}>No content found</Text>} */}
      </View>
      {/* {(!loading && !level) && <Text style={styles.notFound}>Content loading</Text>} */}
    </ScrollView>
  );
}
