import React, { useState } from 'react';
import {
  ImageBackground,
  Text,
  TouchableOpacity,
  View,
  ScrollView,
  StyleSheet,
  StatusBar,
  useWindowDimensions,
} from 'react-native';
import Button from '@components/Button';
import { StackProps } from '@navigator/stack';
import { colors } from '@theme';
import { useAppModule } from '@modules/app.module';
//import { Image } from 'expo-image';
import { mediaUrl } from '@utils/mediaUrl';
import { RightDrawerContext } from './../../navigator/drawer/Drawer';
import { Dimensions } from 'react-native';

const styles = StyleSheet.create({
  root: {
    width: '100%',
    height: '100%',
    backgroundColor: colors.lightGrayPurple,
    borderTopWidth: StyleSheet.hairlineWidth,
    borderColor: colors.ltPurple,
  },
  container: {
    width: '100%',
    maxWidth: 1200,
    padding: 22,
    paddingTop: 0,
    marginHorizontal: 'auto',
    alignItems: 'center',
  },
  catTitle: {
    color: colors.smartPurple,
    includeFontPadding: false,
    textTransform: 'uppercase',
    fontFamily: 'Nunito_900Black',
    fontSize: 16,
    fontWeight: '900',
    lineHeight: 16,
    letterSpacing: 1.92,
    marginTop: 20,
    marginBottom: 10,
  },
  caption: {
    color: colors.smartPurple,
    includeFontPadding: false,
    textTransform: 'uppercase',
    fontFamily: 'Nunito_400Regular',
    fontSize: 12,
    fontWeight: '400',
    lineHeight: 12,
    marginTop: 10,
  },
  title: {
    fontSize: 24,
    marginBottom: 20,
  },
  imgTitle: {
    fontSize: 24,
    color: colors.white,
    textAlign: 'center',
    fontFamily: 'Nunito_900Black',
    fontWeight: '900',
    lineHeight: 22,
    letterSpacing: 2.16,
  },
  overlay: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonTitle: {
    fontSize: 16,
    color: colors.white,
    textAlign: 'center',
  },
  img: {
    height: 173,
    marginBottom: 20,
  },
  wrapper: {
    marginTop: 5,
    cursor: 'pointer',
  },
  button: {
    paddingVertical: 8,
    paddingHorizontal: 16,
    borderRadius: 6,
    marginBottom: 20,
    backgroundColor: colors.smartPurple,
  },
  notFound: {
    color: colors.smartPurple,
    fontSize: 14,
    textAlign: 'center',
    marginTop: 20,
  },
});

export default function Articles({ navigation }: StackProps) {
  const { feed } = useAppModule();
  const { openRightDrawer } = React.useContext(RightDrawerContext);
  const { width } = useWindowDimensions();

  const articles = JSON.parse(JSON.stringify(feed?.articles || []));
  const categories = JSON.parse(JSON.stringify(feed?.categories || []));
  const cats = categories?.sort(
    (a, b) => parseInt(a.attributes?.order) - parseInt(b.attributes?.order),
  );

  window.dataLayer.push({ event: 'page_load', page: 'Articles' });

  return (
    <ScrollView style={styles.root}>
      {categories?.length > 0 && (<View style={styles.container}>
        <StatusBar barStyle="light-content" />
        {/* <TouchableOpacity onPress={() => {openRightDrawer()}}>
          <Text style={styles.caption}>FILTER</Text>
        </TouchableOpacity> */}

        <View>
          <Text style={styles.catTitle}>BROWSE BY THEME</Text>
          {cats?.map((cat, index) => {
            const img = cat.attributes?.image?.data?.attributes?.formats?.thumbnail?.url;
            return (
              <TouchableOpacity
                key={`cat_${cat.id}`}
                onPress={() => {
                  navigation.navigate('ArticlesListStack', { from: 'Articles', catId: cat.id });
                }}>
                <View style={styles.wrapper}>
                  {img ? (
                    <ImageBackground
                      source={mediaUrl(img)}
                      style={{ ...styles.img, width: Math.min(width - 40, 357) }}>
                      <View style={styles.overlay}>
                        <Text key={index} style={styles.imgTitle}>
                          {cat.attributes?.title}
                        </Text>
                      </View>
                    </ImageBackground>
                  ) : (
                    <Text key={index} style={styles.title}>
                      {cat.attributes?.title}
                    </Text>
                  )}
                </View>
              </TouchableOpacity>
            );
          })}
        </View>
        <Button
          title="See All"
          titleStyle={styles.buttonTitle}
          style={{ ...styles.button, width: Math.min(width - 40, 357) }}
          onPress={() => {
            navigation.navigate('ArticlesListStack', { from: 'Articles', catId: 0 });
            //setCategory(0);
          }}
        />
      </View>)}
      {(articles.length === 0) && <Text style={styles.notFound}>No articles found</Text>}
    </ScrollView>
  );
}
