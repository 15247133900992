import React from 'react';
import { Text, TextInput, TextInputProps } from 'react-native';

interface LabeledTextInputProps extends TextInputProps {
  label: string;
  isMandatory?: boolean;
}

export default function LabeledTextInput({ label, isMandatory, ...other }: LabeledTextInputProps) {
  return (
    <>
      <Text
        style={{
          color: 'white',
          fontSize: 12,
          marginBottom: 0,
          marginTop: 20,
          padding: 1.5,
        }}>
        {`${label} ${isMandatory ? '*' : ''}`}
      </Text>
      <TextInput {...other} />
    </>
  );
}
