import React, { useState, useEffect, useRef } from 'react';
import { useNavigation } from '@react-navigation/native';
import { TouchableOpacity, Animated, PanResponder, View, Easing, Image } from 'react-native';
import { songIdPlayingState } from '@utils/recoil';
import { useRecoilState } from 'recoil';
import { colors } from '@theme';
import { Audio } from 'expo-av';
import sleep from './sleep';

const TRACK_SIZE = 1;

const AudioPlayer = props => {
  const navigation = useNavigation();
  const [playing, setPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [trackLayout, setTrackLayout] = useState({});
  const dotOffset = useRef(new Animated.ValueXY()).current;
  const xDotOffsetAtAnimationStart = useRef(0);
  const [soundObject, setSoundObject] = useState(new Audio.Sound());
  const [songIdPlaying, setSongIdPlaying] = useRecoilState(songIdPlayingState);

  useEffect(() => {
    const unsubscribe = navigation.addListener('blur', async () => {
      // console.log('pausing');
      await pause();
    });

    return () => {
      unsubscribe();
    };
  }, [navigation]);

  useEffect(() => {
    if (songIdPlaying !== undefined && songIdPlaying !== props.songId) {
      pause();
    }
  }, [songIdPlaying]);

  const _panResponder = PanResponder.create({
    onMoveShouldSetResponderCapture: () => true,
    onMoveShouldSetPanResponderCapture: () => true,
    onPanResponderGrant: async (e, gestureState) => {
      if (playing) {
        await pause();
      }
      xDotOffsetAtAnimationStart.current = dotOffset.x._value;
      dotOffset.setOffset({ x: dotOffset.x._value });
      dotOffset.setValue({ x: 0, y: 0 });
    },
    onPanResponderMove: (e, gestureState) => {
      Animated.event([null, { dx: dotOffset.x, dy: dotOffset.y }])(e, gestureState);
    },
    onPanResponderTerminationRequest: () => false,
    onPanResponderTerminate: async (evt, gestureState) => {
      const currentOffsetX = xDotOffsetAtAnimationStart.current + dotOffset.x._value;
      if (currentOffsetX < 0 || currentOffsetX > trackLayout.width) {
        await dotOffset.setValue({ x: -xDotOffsetAtAnimationStart.current, y: 0 });
      }
      await dotOffset.flattenOffset();
      await mapAudioToCurrentTime();
    },
    onPanResponderRelease: async (e, { vx }) => {
      const currentOffsetX = xDotOffsetAtAnimationStart.current + dotOffset.x._value;
      if (currentOffsetX < 0 || currentOffsetX > trackLayout.width) {
        await dotOffset.setValue({ x: -xDotOffsetAtAnimationStart.current, y: 0 });
      }
      await dotOffset.flattenOffset();
      await mapAudioToCurrentTime();
    },
  });

  const mapAudioToCurrentTime = async () => {
    await soundObject.setPositionAsync(currentTime);
  };

  const onPressPlayPause = async () => {
    if (playing) {
      await pause();
      return;
    }
    await play();
  };

  const play = async () => {
    await soundObject.playAsync();
    setSongIdPlaying(props.songId);
    setPlaying(true);
    startMovingDot();

    window.dataLayer.push({
      event: 'song_play',
      song: props.songTitle,
    });
  };

  const pause = async () => {
    await soundObject.pauseAsync();
    setPlaying(false);
    Animated.timing(dotOffset).stop();
  };

  const startMovingDot = async () => {
    const status = await soundObject.getStatusAsync();
    const durationLeft = status['durationMillis'] - status['positionMillis'];

    Animated.timing(dotOffset, {
      toValue: { x: trackLayout.width, y: 0 },
      duration: durationLeft,
      easing: Easing.linear,
    }).start(() => animationPausedOrStopped());
  };

  const animationPausedOrStopped = async () => {
    await sleep(200);
    setPlaying(false);
    await soundObject.pauseAsync();
    await dotOffset.setValue({ x: 0, y: 0 });
    await soundObject.setPositionAsync(0);
  };

  const measureTrack = event => {
    setTrackLayout(event.nativeEvent.layout);
  };

  useEffect(() => {
    const loadAudio = async () => {
      await soundObject.loadAsync(props.audio);

      const status = await soundObject.getStatusAsync();
      status.durationMillis = 30000;

      setDuration(status.durationMillis);

      dotOffset.addListener(() => {
        const animatedCurrentTime = dotOffset.x
          .interpolate({
            inputRange: [0, trackLayout.width],
            outputRange: [0, duration],
            extrapolate: 'clamp',
          })
          .__getValue();

        setCurrentTime(animatedCurrentTime);
      });
    };

    loadAudio();

    return async () => {
      await soundObject.unloadAsync();
      dotOffset.removeAllListeners();
    };
  }, [props.audio, dotOffset, trackLayout, duration]);

  const animatedWidth = trackLayout.width !== undefined ? trackLayout.width : 1;

  return (
    <View
      style={{
        marginTop: 15,
        marginBottom: 15,
        color: 'white',
        marginHorizontal: props.fullWidth ? 0 : 20,
        borderRadius: 6,
        backgroundColor: props.color || colors.smartPink,
      }}>
      <View
        style={{
          flex: 0,
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'stretch',
          paddingLeft: 8,
          paddingRight: 8,
          paddingTop: 15,
          paddingBottom: 15,
        }}>
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingLeft: 8,
            paddingRight: 8,
            height: 35,
          }}>
          <TouchableOpacity
            style={{
              width: 50,
              height: 30,
              marginRight: 10,
              marginLeft: 10,
              zIndex: 2,
            }}
            onPress={onPressPlayPause}>
            {playing ? (
              <Image
                source={require('../../../assets/icons/stop.svg')}
                style={{ width: 30, height: 30 }}
              />
            ) : (
              <Image
                source={require('../../../assets/icons/play.svg')}
                style={{ width: 30, height: 30 }}
              />
            )}
          </TouchableOpacity>
          <Animated.View
            onLayout={measureTrack}
            style={{
              flex: 8,
              flexDirection: 'row',
              justifyContent: 'flex-start',
              alignItems: 'center',
              height: TRACK_SIZE,
              borderRadius: TRACK_SIZE / 2,
              backgroundColor: 'white',
            }}>
            <Animated.View
              style={{
                width: dotOffset.x.interpolate({
                  inputRange: [0, animatedWidth],
                  outputRange: [0, animatedWidth],
                  extrapolate: 'clamp',
                }),
                height: TRACK_SIZE,
                backgroundColor: 'lightgrey',
              }}
              {..._panResponder.panHandlers}
            />
          </Animated.View>
        </View>
      </View>
    </View>
  );
};

export default AudioPlayer;
