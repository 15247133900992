import { colors } from "@theme";
import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  root: {
    width: '100%',
    height: '100%',
    backgroundColor: colors.smartPurple,
  },
  container: {
    width: '100%',
    maxWidth: 600,
    marginTop: 20,
    padding: 22,
    marginHorizontal: 'auto',
    backgroundColor: colors.smartPurple,
  },
  input: {
    height: 40,
    padding: 10,
    borderWidth: 1,
    backgroundColor: colors.white,
    borderRadius: 6,
    borderStyle: 'none',
    placeholderTextColor: colors.ltPurple,
  },
  title: {
    fontSize: 24,
    color: colors.white,
    marginTop: 20,
    textAlign: 'center',
  },
  buttonTitle: {
    fontSize: 16,
    color: colors.white,
    textAlign: 'center',
  },
  button: {
    paddingVertical: 8,
    paddingHorizontal: 16,
    marginTop: 32,
    backgroundColor: colors.lightPurple,
    borderRadius: 6,
    borderStyle: 'none',
  },
  link: {
    fontSize: 16,
    marginTop: 20,
    color: colors.white,
    alignSelf: 'center',
    textDecorationLine: 'underline',
  },
});
