import React, { useRef, useState } from 'react';
import { SafeAreaView, TouchableOpacity, Text, View, StyleSheet, ScrollView } from 'react-native';
import { createDrawerNavigator } from '@react-navigation/drawer';
import TabNavigator from '../tab/Tab';
import { DrawerParamList } from './Drawer.typeDefs';
import { colors } from '@theme';
import { AntDesign } from '@expo/vector-icons';
import { Image } from 'react-native';
import { Drawer } from 'react-native-drawer-layout';
import { useAppModule } from '@modules/app.module';
//import { Checkbox, CheckboxGroup,VStack, CheckboxLabel,CheckboxIcon,CheckboxIndicator,CheckIcon   } from '@gluestack-ui/themed';
//import {renderTabBarIcon} from './../tab/Tab';
import Checkbox from 'expo-checkbox';
import { Input, InputField } from '@gluestack-ui/themed';

const renderTabBarIcon = tabName => {
  switch (tabName) {
    case 'HomeTab':
      return <AntDesign name="home" size={26} color={colors.white} />;
    case 'ArticlesTab':
      return (
        <Image
          source={require('../../../assets/images/articles.svg')}
          style={{ width: 26, height: 30, tintColor: colors.white }}
        />
      );
    case 'SongsTab':
      return (
        <Image
          source={require('../../../assets/images/songs.svg')}
          style={{ width: 26, height: 26, tintColor: colors.white }}
        />
      );
    case 'FavoritesTab':
      return (
        <AntDesign name="staro" size={26} color={colors.white} />
      );
    case 'PreferencesTab':
      return (
        <Image
          source={require('../../../assets/images/preferences.svg')}
          style={{ width: 26, height: 26, tintColor: colors.white }}
        />
      );
    // add more...
  }
};

//const Drawer = createDrawerNavigator<DrawerParamList>();

const styles = StyleSheet.create({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    height: '100%',
    backgroundColor: colors.smartPurple,
    color: colors.white,
  },
  rootScroll: {

  },
  catHeader: {
    color: colors.white,
    marginLeft: 20,
    marginTop: 10,
    marginBottom: 10,
    fontSize: 14,
    textTransform: 'uppercase',
    fontFamily: 'Nunito_900Black',
    fontWeight: '900',
  },
  favoriteHeader: {
    marginTop: 5,
    marginBottom: 5,
  },
  tag: {
    color: colors.white,
    marginTop: 0,
    marginBottom: 0,
    fontSize: 14,
    padding: 0,
    textTransform: 'capitalize',
    fontFamily: 'Nunito_500Medium',
  },
  menuItem: {
    color: colors.white,
    marginLeft: 20,
    marginTop: 10,
    marginBottom: 10,
    fontSize: 16,
    fontFamily: 'Nunito_900Black',
    fontWeight: '900',
  },
  tagHeaderWrapper: {
    paddingLeft: 0,
  },
  tagHeaderContainerFirst: {
    marginTop: 10,
  },
  tagHeaderContainerLast: {
    marginBottom: 10,
  },
  tagItemWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    paddingLeft: 20,
    paddingRight: 10,
    marginTop: 2,
    marginBottom: 2,
  },
  menuItemWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    marginLeft: 20,
    marginTop: 10,
    marginBottom: 10,
  },
  section: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  paragraph: {
    fontSize: 15,
  },
  checkbox: {
    margin: 8,
  },
  searchBox: {
    color: colors.white,
    margin: 20,
    backgroundColor: colors.white,
  },
});

const drawerContents = ({ navigation }) => {
  /* {navigation} */
  const handleItemClick = item => {
    navigation.navigate(item.navigation);
  };

  const items = [
    { title: 'Home', navigation: 'HomeTab' },
    { title: 'Articles', navigation: 'ArticlesTab' },
    { title: 'Song Book', navigation: 'SongsTab' },
    { title: 'Favorites', navigation: 'FavoritesTab' },
    { title: 'Preferences', navigation: 'PreferencesTab' },
  ];
  return (
    <SafeAreaView style={{ height: '100%' }}>
      <View style={styles.root}>
        {items.map((item, index) => (
          <TouchableOpacity key={index} onPress={() => handleItemClick(item)}>
            <View style={styles.menuItemWrapper}>
              {renderTabBarIcon(item.navigation)}
              <Text style={styles.menuItem}>{item.title}</Text>
            </View>
          </TouchableOpacity>
        ))}
      </View>
    </SafeAreaView>
  );
};

const RightDrawerContext = React.createContext();
const LeftDrawer = createDrawerNavigator();

const LeftDrawerScreen = () => {
  return (
    <LeftDrawer.Navigator
      initialRouteName="MainDrawer"
      screenOptions={{ headerShown: false }}
      drawerContent={drawerContents}>
      <LeftDrawer.Screen name="MainDrawer" component={TabNavigator} />
    </LeftDrawer.Navigator>
  );
};

function RightDrawerScreen() {
  const {
    feed,
    filterArticlesKeyword,
    filterSongsKeyword,
    filterFavoriteArticles,
    filterFavoriteSongs,
    filterArticles,
    filterSongs,
    filterSongsLevels,
    dispatch,
    setFilterArticles,
    setFilterSongs,
    setFilterSongsLevels /*, filterArticles, filterSongs*/,
    setFilterArticlesKeyword,
    setFilterSongsKeyword,
    setFilterFavoriteArticles,
    setFilterFavoriteSongs,
  } = useAppModule();
  const [rightDrawerOpen, setRightDrawerOpen] = React.useState(false);

  const [mode, setMode] = useState('articles');
  const value = React.useMemo(
    () => ({
      openRightDrawer: (mode = 'articles') => {
        setRightDrawerOpen(true);
        setMode(mode);
      },
      closeRightDrawer: () => setRightDrawerOpen(false),
    }),
    [],
  );

  const handleKeywordChange = (newValue: string) => {
    dispatch(
      mode === 'articles' ? setFilterArticlesKeyword(newValue) : setFilterSongsKeyword(newValue),
    );
  };

  const handleFavoriteChange = (newValue: boolean) => {
    dispatch(
      mode === 'articles' ? setFilterFavoriteArticles(newValue) : setFilterFavoriteSongs(newValue),
    );
  }

  const handleCheckboxChange = (newValue: boolean, tag: any) => {
    if (mode === 'articles') {
      dispatch(
        setFilterArticles(
          newValue === true
            ? [...filterArticles, { id: tag.id, title: tag.title }]
            : filterArticles?.filter(item => item.id !== tag.id),
        ),
      );
    } else {
      dispatch(
        setFilterSongs(
          newValue === true
            ? [...filterSongs, { id: tag.id, title: tag.title }]
            : filterSongs?.filter(item => item.id !== tag.id),
        ),
      );
    }
  };

  const handleLevelChange = (newValue: boolean, level: any) => {
    dispatch(
      setFilterSongsLevels(
        newValue === true
          ? [...filterSongsLevels, { id: level.id, title: level?.title }]
          : filterSongsLevels?.filter(item => item.id !== level.id),
      ),
    );
  };

  const items = mode === 'articles' ? feed?.articleTags : feed?.songTags;
  const keys = Object.keys(items || {});
  keys.sort((a, b) => a - b);
  return (
    <Drawer
      open={rightDrawerOpen}
      onOpen={() => setRightDrawerOpen(true)}
      onClose={() => setRightDrawerOpen(false)}
      // drawerPosition="right"
      drawerType="slide"
      renderDrawerContent={() => {
        return (
          keys.length > 0 && (
            <View style={{ backgroundColor: colors.smartPink, height: '100%', width: '100%' }}>
              <Input style={styles.searchBox} variant="outline" size="md">
                <InputField
                  placeholder="Search Here"
                  value={mode === 'songs' ? filterSongsKeyword : filterArticlesKeyword}
                  onChange={e => handleKeywordChange(e.target.value)}
                />
              </Input>
              <ScrollView style={styles.rootScroll}>
              <React.Fragment>
                <TouchableOpacity
                  style={{...styles.tagHeaderContainerFirst, ...styles.tagHeaderContainerLast}}
                  onPress={() => handleFavoriteChange(!(mode === 'songs' ? filterFavoriteSongs : filterFavoriteArticles))}
                >
                  <View style={{ ...styles.tagItemWrapper, ...styles.tagHeaderWrapper }}>
                    <Text style={{...styles.catHeader, ...styles.favoriteHeader}}>Favorites</Text>
                    <Checkbox
                      style={{...styles.checkbox, ...styles.favoriteHeader}}
                      value={mode === 'songs' ? filterFavoriteSongs : filterFavoriteArticles}
                      onValueChange={() => handleFavoriteChange(!(mode === 'songs' ? filterFavoriteSongs : filterFavoriteArticles))}
                    />
                  </View>
                </TouchableOpacity>
              </React.Fragment>
              {mode === 'songs' && (
                <React.Fragment>
                  <Text style={styles.catHeader}>Age</Text>
                  {feed?.levels?.map((level, index) => {
                    return (
                      <TouchableOpacity
                        key={index}
                        onPress={() => {
                          handleLevelChange(!filterSongsLevels.find(l => l.id === level.id), level);
                        }}
                      >
                        <View style={{ ...styles.tagItemWrapper }}>
                          <Text style={styles.tag}>{`${level?.title}`}</Text>
                          <Checkbox
                            style={styles.checkbox}
                            value={filterSongsLevels.find(l => l.id === level.id)}
                            onValueChange={() =>
                              handleLevelChange(
                                !filterSongsLevels.find(l => l.id === level.id),
                                level,
                              )
                            }
                          />
                        </View>
                      </TouchableOpacity>
                    );
                  })}
                </React.Fragment>
              )}
              {keys?.map((key, index) => {
                const tagCategory = items?.[key];
                return (
                  <React.Fragment key={index}>
                    <Text style={styles.catHeader}>{tagCategory.title}</Text>
                    {tagCategory.tags.map((tag, jindex) => {
                      //checkbox with tag title
                      return (
                        <TouchableOpacity
                          key={jindex}
                          onPress={() => {
                            handleCheckboxChange(
                              mode === 'articles'
                                ? !filterArticles?.find(t => t.id === tag.id)
                                : !filterSongs?.find(t => t.id === tag.id),
                              tag,
                            );
                          }}>
                          <View style={{ ...styles.tagItemWrapper }}>
                            <Text style={styles.tag}>{tag.title}</Text>
                            <Checkbox
                              style={styles.checkbox}
                              value={
                                mode === 'articles'
                                  ? filterArticles?.find(t => t.id === tag.id)
                                  : filterSongs?.find(t => t.id === tag.id)
                              }
                              onValueChange={() => {
                                handleCheckboxChange(
                                  mode === 'articles'
                                    ? !filterArticles?.find(t => t.id === tag.id)
                                    : !filterSongs?.find(t => t.id === tag.id),
                                  tag,
                                );
                              }}
                            />
                          </View>
                        </TouchableOpacity>
                      );
                    })}
                  </React.Fragment>
                );
              })}
              </ScrollView>
            </View>
          )
        );
      }}>
      <RightDrawerContext.Provider value={value}>
        <LeftDrawerScreen />
      </RightDrawerContext.Provider>
    </Drawer>
  );
}

export { RightDrawerContext };
export default RightDrawerScreen;
