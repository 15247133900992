import React, { useState } from 'react';
import {
  ImageBackground,
  TouchableOpacity,
  Text,
  View,
  StyleSheet,
  ScrollView,
  StatusBar,
} from 'react-native';
// import Button from '@components/Button';
// import { Image } from 'expo-image';
// import {mediaUrl} from '@utils/mediaUrl'
import { StackProps } from '@navigator/stack';
import { colors } from '@theme';
import { useAppModule } from '@modules/app.module';
import ArticleListItem from '@components/ArticleListItem';
import { RightDrawerContext } from './../../navigator/drawer/Drawer';
import { Button } from '@gluestack-ui/themed';

const styles = StyleSheet.create({
  root: {
    width: '100%',
    height: '100%',
    backgroundColor: colors.lightGrayPurple,
    borderTopWidth: StyleSheet.hairlineWidth,
    borderColor: colors.ltPurple,
  },
  scrollRoot: {
    width: '100%',
    height: '100%',
    paddingTop: 0,
    marginTop: 0,
  },
  container: {
    width: '100%',
    maxWidth: 1200,
    padding: 22,
    paddingTop: 0,
    marginHorizontal: 'auto',
    marginTop: 0,
  },
  author: {
    color: colors.smartPurple,
    textAlign: 'left',
    includeFontPadding: false,
    fontFamily: 'Nunito_500Medium',
    fontSize: 12,
    fontWeight: '500',
    lineHeight: 12,
    marginBottom: 10,
  },
  text: {
    color: colors.smartPurple,
    textAlign: 'left',
    includeFontPadding: false,
    fontFamily: 'Nunito_500Medium',
    fontSize: 12,
    fontWeight: '500',
    lineHeight: 12,
  },
  catTitle: {
    color: colors.smartPurple,
    includeFontPadding: false,
    textTransform: 'uppercase',
    fontFamily: 'Nunito_900Black',
    fontSize: 16,
    fontWeight: '900',
    lineHeight: 16,
    letterSpacing: 1.92,
    marginTop: 10,
    marginBottom: 10,
  },
  caption: {
    color: colors.smartPurple,
    includeFontPadding: false,
    textTransform: 'uppercase',
    fontFamily: 'Nunito_400Regular',
    fontSize: 12,
    fontWeight: '400',
    lineHeight: 12,
    marginTop: 10,
    marginBottom: 0,
    marginRight: 20,
    marginLeft: 20,
  },
  title: {
    fontSize: 22,
    marginBottom: 10,
    marginTop: 10,
    color: colors.smartPurple,
    includeFontPadding: false,
    fontFamily: 'Nunito_900Black',
    fontWeight: '900',
    lineHeight: 22,
  },
  buttonTitle: {
    fontSize: 16,
    color: colors.white,
    textAlign: 'center',
  },
  img: {
    width: 364,
    height: 173,
    marginBottom: 20,
  },
  wrapper: {
    marginTop: 5,
    marginBottom: 20,
    paddingBottom: 20,
    cursor: 'pointer',
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderColor: colors.ltPurple,
  },
  button: {
    paddingVertical: 8,
    paddingHorizontal: 16,
    borderRadius: 5,
    backgroundColor: colors.smartPurple,
  },
  readMore: {
    color: colors.smartPurple,
    marginTop: 10,
    textAlign: 'left',
    includeFontPadding: false,
    textTransform: 'uppercase',
    fontFamily: 'Nunito_900Black',
    fontSize: 12,
    fontWeight: '900',
    lineHeight: 12,
  },
  tag: {
    backgroundColor: colors.ltPurple,
    borderRadius: 4,
    color: colors.smartPurple,
    includeFontPadding: false,
    textTransform: 'uppercase',
    fontFamily: 'Nunito_400Regular',
    fontSize: 12,
    fontWeight: '600',
    lineHeight: 12,
    letterSpacing: 0.72,
    padding: 5,
    paddingRight: 10,
    marginRight: 10,
    marginBottom: 5,
  },
  tagWrapper: {
    marginTop: 10,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    alignItems: 'baseline',
    alignContent: 'flex-start',
  },
  filterItems: {
    marginTop: 0,
    marginBottom: 10,
    marginRight: 20,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    alignItems: 'baseline',
    alignContent: 'flex-start',
  },
  deleteFilterButton: {
    backgroundColor: colors.transparent,
    paddingLeft: 8,
    paddingRight: 0,
    height: 'unset',
  },
  notFound: {
    color: colors.smartPurple,
    fontSize: 14,
    textAlign: 'center',
    marginTop: 20,
  },
});

export default function Articles({ navigation, route }: StackProps) {
  const { catId } = route.params;
  const { openRightDrawer } = React.useContext(RightDrawerContext);
  const {
    feed,
    user,
    filterArticles,
    filterArticlesKeyword,
    filterFavoriteArticles,
    dispatch,
    setFilterArticles,
    setFilterArticlesKeyword,
    setFilterFavoriteArticles,
  } = useAppModule();

  const articles = JSON.parse(JSON.stringify(feed?.articles || []));
  const categories = JSON.parse(JSON.stringify(feed?.categories || []));
  const favoriteArticles = JSON.parse(JSON.stringify(user?.user?.favoriteArticles || []));

  const filteredArcicles = articles?.filter((a: any) => {
    let display = true;
    if (catId && a?.article_category?.id !== catId) display = false;
    if (filterArticles?.length > 0) {
      let found = false;
      filterArticles.forEach(tag => {
        if (a?.tags?.find((t: any) => t.id === tag.id)) found = true;
      });
      if (!found) display = false;
    }

    if (!!filterArticlesKeyword && filterArticlesKeyword !== '') {
      if (!a?.title?.toLowerCase().includes(filterArticlesKeyword.toLowerCase())) display = false;
    }

    if (filterFavoriteArticles) {
      let found = false;
      favoriteArticles.forEach(s => {
        if (a?.id === s.id) found = true;
      });
      if (!found) display = false;
    }

    return display;
  });

  window.dataLayer.push({
    event: 'page_load',
    page: 'Articles List',
    category: categories.find((c: any) => c.id === catId)?.attributes?.title || 'All',
  });

  return (
    <ScrollView style={styles.root}>
      <View style={styles.container}>
        <StatusBar barStyle="light-content" />

        <Text style={styles.catTitle}>
          {categories.find((c: any) => c.id === catId)?.attributes?.title}
        </Text>
        {articles?.length > 0 && (<TouchableOpacity
          onPress={() => {
            openRightDrawer();
          }}>
          <View style={styles.filterItems}>
            <Text style={styles.caption}>FILTER</Text>
            {(filterArticles?.length > 0 ||
              (!!filterArticlesKeyword && filterArticlesKeyword !== '') ||
              filterFavoriteArticles) && (
              <React.Fragment>
                {filterArticles?.map((tag, ind) => (
                  <Text key={`tag2_${ind}`} style={styles.tag}>
                    {tag.title}
                    <Button
                      style={styles.deleteFilterButton}
                      size="sm"
                      onPress={() =>
                        dispatch(
                          setFilterArticles(filterArticles.filter((t: any) => t.id !== tag.id)),
                        )
                      }>
                      x
                    </Button>
                  </Text>
                ))}
                {!!filterArticlesKeyword && filterArticlesKeyword !== '' && (
                  <>
                    <Text style={{ ...styles.tag, backgroundColor: colors.ltYellow }}>
                      {filterArticlesKeyword}
                      <Button
                        style={styles.deleteFilterButton}
                        size="sm"
                        onPress={() => dispatch(setFilterArticlesKeyword(''))}>
                        x
                      </Button>
                    </Text>
                  </>
                )}
                {filterFavoriteArticles && (
                  <>
                    <Text style={{ ...styles.tag, backgroundColor: colors.ltYellow }}>
                      Favorite Articles
                      <Button
                        style={styles.deleteFilterButton}
                        size="sm"
                        onPress={() => dispatch(setFilterFavoriteArticles(false))}>
                        x
                      </Button>
                    </Text>
                  </>
                )}
              </React.Fragment>
            )}
          </View>
        </TouchableOpacity>)}
        {filteredArcicles.map((article, index) => (
          <ArticleListItem
            navigation={navigation}
            key={`art_${index}`}
            article={article}
            showFavorite={true}
          />
        ))}
        {(filteredArcicles.length === 0 || articles.length === 0) && <Text style={styles.notFound}>No articles found</Text>}
      </View>
    </ScrollView>
  );
}
