import React from 'react';
import { Text, TextInput, TextInputProps } from 'react-native';
//colors
import { colors } from '@theme';

interface LabeledTextInputProps extends TextInputProps {
  message: string;
}

export default function ErrorDisplay({ message }: LabeledTextInputProps) {
  return (
    <>
      <Text
        style={{
          color: 'white',
          backgroundColor: colors.smartPink,
          fontSize: 12,
          marginBottom: 0,
          marginTop: 20,
          padding: 10,
          borderRadius: 6,
        }}>
        {message}
      </Text>
    </>
  );
}
