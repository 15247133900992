import React, {useState} from 'react';
import { Text, Pressable, Image} from 'react-native';
import { styles } from '../styles';
import LabeledTextInput from '@components/LabeledTextInput';
import Button from '@components/Button';
import { useAppModule } from '@modules/app.module';
import axios from 'axios';
import ErrorDisplay from '@components/ErrorDisplay';
import MessageDisplay from '@components/MessageDisplay';

export default function LoginComponent({verifyEmail, registered, message, registeredEmail}) {
  const { dispatch, loginUser } = useAppModule();
  const [email, onChangeEmail] = useState('');
  const [password, onChangePassword] = useState('');
  const [code, onChangeCode] = useState('');
  const [error, setError] = useState('');
  const [resent, setResent] = useState(false);

  const handleLogin = async () => {
    setError('');
    try {
      const result = await axios.post(`${process.env.EXPO_PUBLIC_CMS}/api/smartstart/auth`, {
        identifier: email,
        password: password,
      });
      await dispatch(loginUser(result.data));
    } catch (error) {
      if (error?.response?.status === 481) {
        verifyEmail(email);
      } else {
        setError(error?.response?.data?.error?.message || 'authentication error');
      }
    }
  };

  const handleVerify = async () => {
    setError('');
    try {
      const result = await axios.get(`${process.env.EXPO_PUBLIC_CMS}/api/auth/email-confirmation?confirmation=${code}`);
      await dispatch(loginUser(result.data));
    } catch (error) {
      console.log(error);
      setError('wrong code');
    }
  };

  return (
    <>
      {!!registered && <Text style={styles.title}>You have been registered. Please enter the confirmation code from your email address at {registeredEmail}:</Text>}
      {!registered && <Text style={styles.title}>Sign in with your existing Smart Start account</Text> }

      {/* resend email */}
      {resent && registered ? <Text style={{ color: 'white',alignSelf: 'center',}}> Email resent. </Text>: null}
      {!resent && registered ?  <Pressable onPress={async ()=>{
        try {
          await axios.post(`${process.env.EXPO_PUBLIC_CMS}/api/auth/send-email-confirmation`, { email: registeredEmail });
          setResent(true);
        } catch (error) {
          setError('Email sending error');
        }
      }} >
      <Text
        style={{
          color: 'white',
          alignSelf: 'center',
          textDecorationLine: 'underline'
        }}>
        Resend email
      </Text>
      </Pressable>: null}

      {!!message && <MessageDisplay message={message} />}
      {!!error && <ErrorDisplay message={error}/>}

      {registered? 
      <React.Fragment>
        <LabeledTextInput
          label="Confirmation Code"
          style={styles.input}
          isMandatory={true}
          value={code}
          onChangeText={onChangeCode}
          keyboardType="numeric"
          placeholder="xxxxxxxx"
        />
        <Button
          title="Verify"
          titleStyle={styles.buttonTitle}
          style={styles.button}
          disabled={!code}
          onPress={handleVerify}
        />
      </React.Fragment> :
      <React.Fragment>
        <LabeledTextInput
          label="Email address"
          style={styles.input}
          isMandatory={true}
          value={email}
          onChangeText={onChangeEmail}
          placeholder="john.doe@example.com"
          keyboardType="email-address"
        />

        <LabeledTextInput
          label="Password"
          style={styles.input}
          isMandatory={true}
          value={password}
          onChangeText={onChangePassword}
          placeholder="Password"
          secureTextEntry={true}
          keyboardType="default"
        />

        <Button
          title="Login"
          titleStyle={styles.buttonTitle}
          style={styles.button}
          disabled={!email || !password}
          onPress={handleLogin}
        />
      </React.Fragment>}
    </>
  );
}
