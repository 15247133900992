import React from 'react';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { StackParamList } from './Stack.typeDefs';
import { DrawerProps } from '../drawer/Drawer.typeDefs';
import {
  StackHeaderLeft,
  StackHeaderLeftDark,
  StackHeaderTitle,
  StackHeaderTitleDark,
} from './components';
import { colors } from '@theme';
import { View, Text, StyleSheet } from 'react-native';
import { AntDesign } from '@expo/vector-icons';
// views
import Home from '@views/Home';
import Favorites from '@views/Favorites';
import Details from '@views/Details';
import Articles from '@views/Articles';
import ArticlesList from '@views/ArticlesList';
import Songs from '@views/Songs';
import Preferences from '@views/Preferences';
import { Image } from 'react-native';
import { useAppModule } from '@modules/app.module';

const styles = StyleSheet.create({
  root: {
    width: '100%',
    height: '100%',
    backgroundColor: colors.lightGrayPurple,
  },
  container: {
    flex: 1,
    flexDirection: 'column',
    alignItems: 'baseline',
    justifyContent: 'flex-start',
    borderTopWidth: StyleSheet.hairlineWidth,
    borderColor: colors.ltPurple,
    marginHorizontal: 20,
  },
  wrapper: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  homeTitleWrapper: {
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  headerTitle: {
    color: colors.smartPurple,
    textAlign: 'center',
    includeFontPadding: false,
    fontFamily: 'Nunito_900Black',
    fontSize: 20,
    fontWeight: '900',
    lineHeight: 10,
    marginTop: 5,
  },
  homeHeaderTitle: {
    color: colors.smartPurple,
    textAlign: 'center',
    fontFamily: 'Nunito_900Black',
    fontSize: 16,
    fontWeight: '900',
    lineHeight: 10,
    marginTop: 5,
  },
});

const Stack = createNativeStackNavigator<StackParamList>();

const navigationPropsHome = {
  headerTintColor: colors.white,
  headerStyle: {
    backgroundColor: colors.smartPurple,
    shadowColor: 'transparent',
    borderWidth: 0,
    elevation: 0,
  },
  headerTitleStyle: { fontSize: 18 },
  headerTitleAlign: 'center',
};

const navigationProps = {
  headerTintColor: colors.smartPurple,
  headerStyle: {
    backgroundColor: colors.lightGrayPurple,
    shadowColor: 'transparent',
    borderWidth: 0,
    elevation: 0,
  },
  headerTitleStyle: { fontSize: 18, color: colors.smartPurple },
  headerTitleAlign: 'center',
};

export function HomeStackNavigator({ navigation }: DrawerProps) {
  const { user } = useAppModule();

  const expirationTimestamp = user?.user?.redemptionCodeExpirationTimestamp * 1;
  const now = Date.now();
  const gracePeriod = 1000 * 60 * 60 * 24 * 30; // 30 days
  const isExpired = !!expirationTimestamp && expirationTimestamp < now;
  const isInGracePeriod = !!expirationTimestamp && expirationTimestamp < now + gracePeriod;
  const isRedemptionCodeExpiring = isExpired || isInGracePeriod;

  return (
    <Stack.Navigator screenOptions={navigationProps}>
      <Stack.Screen
        component={Home}
        initialParams={{ isRedemptionCodeExpiring }}
        name="HomeStack"
        options={{
          title: 'Home',
          headerTitle: () => (
            <View style={styles.homeTitleWrapper}>
              <Text style={styles.homeHeaderTitle}>Welcome{user.user.parentName ? `,` : ``}</Text>
              <Text style={styles.homeHeaderTitle}>{user.user.parentName}</Text>
            </View>
          ),
          headerRight: () => <StackHeaderTitleDark navigation={navigation}/>,
          headerLeft: () => <StackHeaderLeftDark onPress={() => navigation.toggleDrawer()} />,
        }}
      />
      <Stack.Screen
        component={Details}
        name="DetailsStack"
        options={{
          title: 'Details',
          headerTitle: 'Details',
          headerTintColor: colors.smartPurple,
          headerRight: () => <StackHeaderTitleDark navigation={navigation}/>,
          headerTitleAlign: 'center',
        }}
      />
      <Stack.Screen
        component={ArticlesList}
        name="ArticlesListStack"
        options={{
          title: 'Articles',
          headerTintColor: colors.white,
          headerRight: () => <StackHeaderTitle navigation={navigation}/>,
          headerTitleAlign: 'center',
        }}
      />
    </Stack.Navigator>
  );
}

export function ArticlesStackNavigator({ navigation }: DrawerProps) {
  return (
    <Stack.Navigator screenOptions={navigationProps}>
      <Stack.Screen
        component={Articles}
        name="ArticlesStack"
        options={{
          title: 'Articles',
          headerTitle: () => (
            <View style={styles.wrapper}>
              <Image
                source={require('../../../assets/images/articles.svg')}
                style={{ width: 24, height: 30, marginRight: 5, tintColor: colors.smartPurple }}
              />
              <Text style={styles.headerTitle}>Articles</Text>
            </View>
          ),
          headerRight: () => <StackHeaderTitleDark navigation={navigation} />,
          headerLeft: () => <StackHeaderLeftDark onPress={() => navigation.toggleDrawer()} />,
          headerTitleAlign: 'center',
        }}
      />
      <Stack.Screen
        component={Details}
        name="DetailsStack"
        options={{
          title: 'Articles',
          headerTitle: () => (
            <View style={styles.wrapper}>
              <Image
                source={require('../../../assets/images/articles.svg')}
                style={{ width: 24, height: 30, marginRight: 5, tintColor: colors.smartPurple }}
              />
              <Text style={styles.headerTitle}>Articles</Text>
            </View>
          ),
          headerRight: () => <StackHeaderTitleDark navigation={navigation}/>,
          headerTitleAlign: 'center',
        }}
      />
      <Stack.Screen
        component={ArticlesList}
        name="ArticlesListStack"
        options={{
          title: 'Articles',
          headerTitle: () => (
            <View style={styles.wrapper}>
              <Image
                source={require('../../../assets/images/articles.svg')}
                style={{ width: 24, height: 30, marginRight: 5, tintColor: colors.smartPurple }}
              />
              <Text style={styles.headerTitle}>Articles</Text>
            </View>
          ),
          headerRight: () => <StackHeaderTitleDark navigation={navigation}/>,
          headerTitleAlign: 'center',
        }}
      />
    </Stack.Navigator>
  );
}

export function SongsStackNavigator({ navigation }: DrawerProps) {
  return (
    <Stack.Navigator screenOptions={navigationProps}>
      <Stack.Screen
        component={Songs}
        name="SongsStack"
        options={{
          title: 'Song Book',
          headerTitle: () => (
            <View style={styles.wrapper}>
              <Image
                source={require('../../../assets/images/songs.svg')}
                style={{ width: 30, height: 30, tintColor: colors.smartPurple }}
              />
              <Text style={styles.headerTitle}>Song Book</Text>
            </View>
          ),
          headerRight: () => <StackHeaderTitleDark navigation={navigation}/>,
          headerLeft: () => <StackHeaderLeftDark onPress={() => navigation.toggleDrawer()} />,
          headerTitleAlign: 'center',
        }}
      />
      <Stack.Screen
        component={Details}
        name="DetailsStack"
        options={{
          title: 'Details',
          headerTitle: () => (
            <View style={styles.wrapper}>
              <Image
                source={require('../../../assets/images/songs.svg')}
                style={{ width: 30, height: 30, tintColor: colors.smartPurple }}
              />
              <Text style={styles.headerTitle}>Song Book</Text>
            </View>
          ),
          headerRight: () => <StackHeaderTitleDark navigation={navigation}/>,
          headerTitleAlign: 'center',
        }}
      />
    </Stack.Navigator>
  );
}

export function FavoritesStackNavigator({ navigation }: DrawerProps) {
  const { user } = useAppModule();

  const expirationTimestamp = user?.user?.redemptionCodeExpirationTimestamp * 1;
  const now = Date.now();
  const gracePeriod = 1000 * 60 * 60 * 24 * 30; // 30 days
  const isExpired = !!expirationTimestamp && expirationTimestamp < now;
  const isInGracePeriod = !!expirationTimestamp && expirationTimestamp < now + gracePeriod;
  const isRedemptionCodeExpiring = isExpired || isInGracePeriod;

  return (
    <Stack.Navigator screenOptions={navigationProps}>
      <Stack.Screen
        component={Favorites}
        initialParams={{ isRedemptionCodeExpiring }}
        name="FavoritesStack"
        options={{
          title: 'Home',
          headerTitle: () => (
            <View style={styles.wrapper}>
              <AntDesign name="staro" size={30} color={colors.darkPurple} />
              <Text style={styles.headerTitle}>Favorites</Text>
            </View>
          ),
          headerRight: () => <StackHeaderTitleDark navigation={navigation}/>,
          headerLeft: () => <StackHeaderLeftDark onPress={() => navigation.toggleDrawer()} />,
        }}
      />
      <Stack.Screen
        component={Details}
        name="DetailsStack"
        options={{
          title: 'Details',
          headerTitle: 'Details',
          headerTintColor: colors.smartPurple,
          headerRight: () => <StackHeaderTitleDark navigation={navigation}/>,
          headerTitleAlign: 'center',
        }}
      />
      <Stack.Screen
        component={ArticlesList}
        name="ArticlesListStack"
        options={{
          title: 'Articles',
          headerTintColor: colors.white,
          headerRight: () => <StackHeaderTitle navigation={navigation}/>,
          headerTitleAlign: 'center',
        }}
      />
    </Stack.Navigator>
  );
}

export function PreferencesStackNavigator({ navigation }: DrawerProps) {
  return (
    <Stack.Navigator screenOptions={navigationPropsHome}>
      <Stack.Screen
        component={Preferences}
        name="PreferencesStack"
        options={{
          title: 'Preferences',
          headerTitle: () => (
            <View style={styles.wrapper}>
              <Image
                source={require('../../../assets/images/preferences.svg')}
                style={{ width: 30, height: 30, marginRight: 5, tintColor: colors.white }}
              />
              <Text style={{...styles.headerTitle, fontSize:15, color:colors.white}}>Preferences</Text>
            </View>
          ),
          headerRight: () => <StackHeaderTitle navigation={navigation}/>,
          headerLeft: () => <StackHeaderLeft onPress={() => navigation.toggleDrawer()} />,
          headerTitleAlign: 'center',
        }}
      />
      <Stack.Screen
        component={Details}
        name="DetailsStack"
        options={{
          title: 'Details',
          headerRight: () => <StackHeaderTitle navigation={navigation}/>,
          headerTitleAlign: 'center',
        }}
      />
    </Stack.Navigator>
  );
}
