import { useState } from 'react';
import { Text, View, ScrollView, StatusBar, Image } from 'react-native';
import { styles } from './styles';
import { NavigationContainer } from '@react-navigation/native';
import RegistrationComponent from './components/RegistrationComponent';
import LoginComponent from './components/LoginComponent';
import ForgotPasswordComponent from './components/ForgotPasswordComponent';

export default function AuthScreen() {
  const [layout, onChangeLayout] = useState('login');
  const [email, setEmail] = useState('');

  const links = {
    login: {
      value: 'Log In',
      onPress: () => onChangeLayout('login'),
    },
    register: {
      value: 'Register',
      onPress: () => onChangeLayout('register'),
    },
    forgotPassword: {
      value: 'Forgot password?',
      onPress: () => onChangeLayout('forgotPassword'),
    },
  };
  const linksToShow = Object.keys(links).filter(key => key !== layout && key !== 'registered'); //&& !(layout==='registered' && key === 'login' )

  const authScreens = {
    login: (
      <LoginComponent
        verifyEmail={registeredEmail => {
          setEmail(registeredEmail);
          onChangeLayout('registered');
        }}
      />
    ),
    registered: <LoginComponent registered={true} registeredEmail={email} />,
    register: (
      <RegistrationComponent
        verifyEmail={registeredEmail => {
          setEmail(registeredEmail);
          onChangeLayout('registered');
        }}
      />
    ),
    forgotPassword: <ForgotPasswordComponent />,
  };

  return (
    <NavigationContainer
      documentTitle={{
        formatter: () => `Smart Start${links?.[layout]?.value ? ` - ${links?.[layout]?.value}` : ''}`,
      }}>
      <ScrollView style={styles.root}>
        <View style={styles.container}>
          <StatusBar barStyle="light-content" />

          <Image
            style={{
              width: 87,
              height: 41,
              alignSelf: 'center',
            }}
            source={require('./../../../assets/images/logo.png')}
          />

          {authScreens[layout]}

          {linksToShow.map(key => {
            const { value, onPress } = links[key];
            return (
              <Text key={key} style={styles.link} onPress={onPress}>
                {value}
              </Text>
            );
          })}
        </View>
      </ScrollView>
    </NavigationContainer>
  );
}
