import { useEffect, useState } from 'react';
import { Text } from 'react-native';
import { styles } from '../styles';
import LabeledTextInput from '@components/LabeledTextInput';
import axios from 'axios';
import Button from '@components/Button';
import DropDownPicker from 'react-native-dropdown-picker';
import { cmsAPI } from '@utils/cmsApi';
import ErrorDisplay from '@components/ErrorDisplay';

export default function RegistrationComponent({ verifyEmail }) {
  const [error, setError] = useState('');
  const [open, setOpen] = useState(false);
  const [levels, setLevels] = useState([]);

  useEffect(() => {
    fetchLevels()
  }, []);

  const [parentName, onChangeParentName] = useState('');
  const [parentEmail, onChangeParentEmail] = useState('');
  const [studentName, onChangeStudentName] = useState('');
  const [studentLevel, onChangeStudentLevel] = useState('');
  const [teacherName, onChangeTeacherName] = useState('');
  const [schoolName, onChangeSchoolName] = useState('');
  const [password, onChangePassword] = useState('');
  const [redemptionCode, onChangeRedemptionCode] = useState('');

  const fetchLevels = async () => {
    try {
      setError('');
      const result = await cmsAPI.get(`${process.env.EXPO_PUBLIC_CMS}/api/levels`)
      if (result?.data) {
        setLevels(result.data.map((l: any) => ({ label: l?.title, value: l.id })));
      } else {
        throw new Error();
      }
    } catch (err) {
      setError('Unexpected error during fetching levels');
    }
  }

  const handleRegister = async () => {
    setError('');
    try {
      const result = await axios.post(`${process.env.EXPO_PUBLIC_CMS}/api/auth/local/register`, {
        username: parentEmail,
        email: parentEmail,
        parentName: parentName,
        studentName: studentName,
        level: studentLevel || null,
        teacherName: teacherName,
        schoolName: schoolName,
        password: password,
        redemptionCode: redemptionCode,
      });
      verifyEmail(result.data.user.email);
    } catch (error) {
      const msg =
        error?.response?.data?.error?.details?.errors?.[0]?.message ||
        error?.response?.data?.error?.message ||
        'Unexpected error during registration';
      setError(msg);
    }
  };

  return (
    <>
      <Text style={styles.title}>Register Smart Start account</Text>

      {!!error && <ErrorDisplay message={error} />}

      <LabeledTextInput
        label="Parent/Care Provider Name"
        style={styles.input}
        isMandatory={true}
        value={parentName}
        onChangeText={onChangeParentName}
        placeholder="John Doe"
        keyboardType="default"
      />

      <LabeledTextInput
        label="Parent/Care Provider Email"
        style={styles.input}
        isMandatory={true}
        value={parentEmail}
        onChangeText={onChangeParentEmail}
        placeholder="john.doe@example.com"
        keyboardType="email-address"
      />

      <LabeledTextInput
        label="Student Name"
        style={styles.input}
        isMandatory={true}
        value={studentName}
        onChangeText={onChangeStudentName}
        placeholder="Jane Doe"
        keyboardType="default"
      />

      <Text
        style={{
          color: 'white',
          fontSize: 12,
          marginBottom: 0,
          marginTop: 20,
          padding: 1.5,
        }}>
        Student's age*
      </Text>
      <DropDownPicker
        open={open}
        value={studentLevel}
        items={levels}
        setOpen={setOpen}
        setValue={onChangeStudentLevel}
        placeholder="Select age"
        style={{ marginBottom: 0, marginHorizontal:'auto', marginTop:0 }}
      />

      <LabeledTextInput
        label="Teacher Name"
        style={styles.input}
        onChangeText={onChangeTeacherName}
        isMandatory={true}
        value={teacherName}
        placeholder="Jane Doe"
        keyboardType="default"
      />
      <LabeledTextInput
        label="School Name"
        style={styles.input}
        onChangeText={onChangeSchoolName}
        isMandatory={true}
        value={schoolName}
        placeholder="School name"
        keyboardType="default"
      />

      <LabeledTextInput
        label="Password"
        style={styles.input}
        onChangeText={onChangePassword}
        isMandatory={true}
        value={password}
        placeholder="Password"
        secureTextEntry={true}
        keyboardType="default"
      />
      <Text
        style={{
          color: 'white',
          fontSize: 12,
          marginBottom: 0,
          marginTop: 0,
          padding: 1.5,
        }}>
        at least 8 characters, one special character, one number, and one capital letter
      </Text>

      <LabeledTextInput
        label="Redemption Code"
        style={styles.input}
        onChangeText={onChangeRedemptionCode}
        value={redemptionCode}
        placeholder="Redemption Code"
        keyboardType="default"
      />

      <Button
        title="Register"
        titleStyle={styles.buttonTitle}
        style={styles.button}
        disabled={!parentName || !parentEmail || !studentName || !studentLevel || !teacherName || !schoolName || !password}
        onPress={handleRegister}
      />
    </>
  );
}
