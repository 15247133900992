import { useEffect, useState } from 'react';
import { View, Text } from 'react-native';
import { NavigationContainer } from '@react-navigation/native';
import { useAppModule } from '@modules/app.module';
import DrawerNavigator from './drawer';
import { colors } from '@theme';
import AuthScreen from '@views/Auth';
import AccessDeniedComponent from '@views/Auth/components/AccessDeniedComponent';

function Navigator() {
  const { dispatch, user, loading, loggedIn, loadUser, loadFeed } = useAppModule();
  const [authorized, setAuthorized] = useState('');

  useEffect(() => {
    dispatch(loadUser());
    if (!loggedIn) return;
    const expirationTimestamp = user?.user?.redemptionCodeExpirationTimestamp * 1;
    const now = Date.now();
    const gracePeriod = 1000 * 60 * 60 * 24 * 30; // 30 days
    const isExpired = expirationTimestamp < now;
    const isInGracePeriod = isExpired && expirationTimestamp + gracePeriod > now;
    const isAuthorized = !isExpired || isInGracePeriod;
    setAuthorized(isAuthorized ? 'yes' : 'no');
    if (isAuthorized) dispatch(loadFeed());
  }, [loggedIn, user?.user?.redemptionCodeExpirationTimestamp]);

  if (loading) {
    return (
      <View
        style={{
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: colors.smartPurple,
        }}>
        <Text
          style={{
            color: 'white',
          }}>
          Loading...
        </Text>
      </View>
    );
  }

  if (!loggedIn) {
    return <AuthScreen />;
  }

  if (authorized === 'no') {
    return <AccessDeniedComponent />;
  }

  if (authorized === 'yes')
    return (
      <NavigationContainer
        documentTitle={{
          formatter: (options, route) => `Smart Start - ${options?.title ?? route?.name}`,
        }}>
        <DrawerNavigator />
      </NavigationContainer>
    );

  return <></>;
}

export default Navigator;
