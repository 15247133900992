import { MMKV } from 'react-native-mmkv';
import { Storage } from 'expo-storage';
import { Platform } from 'react-native';

let mmkv = null;
if (Platform.OS === 'web') {
  mmkv = new MMKV();
}
export class storageClass {
  set = async (key, value) => {
    if (Platform.OS === 'web') {
      mmkv.set(key, value);
    } else {
      await Storage.setItem({
        key: key,
        value: value,
      });
    }
  };

  get = async key => {
    if (Platform.OS === 'web') {
      return mmkv.getString(key);
    } else {
      return await Storage.getItem({ key: key });
    }
  };

  delete = async key => {
    if (Platform.OS === 'web') {
      return mmkv.delete(key);
    } else {
      return await Storage.removeItem({ key: key });
    }
  };
}

export const storage = new storageClass();
