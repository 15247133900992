export const colors = {
  darkPurple: '#231d54',
  purple: '#8100ff',
  lightPurple: '#9388db',
  lightGrayPurple: '#f7f7fb',
  pink: '#ff3d69',
  gray: '#797777',
  black: '#000000',
  white: '#ffffff',
  transparent: 'transparent',
  smartPurple: '#2E2C6E',
  smartPink: '#C9326A',
  smartOrange: '#E16E38',
  smartYellow: '#F7CD46',
  smartTeal: '#4AA49D',
  ltPurple: '#B7B6F8',
  ltPink: '#F4B8E7',
  ltOrange: '#F6C7AE',
  ltYellow: '#FDF1C0',
  ltTeal: '#C5EEE7'
};
