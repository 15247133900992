import React from 'react';
import { Platform } from 'react-native';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { AntDesign } from '@expo/vector-icons';
import { colors } from '@theme';
import { TabParamList, TabBarStatus } from './Tab.typeDefs';
import {
  HomeStackNavigator,
  ArticlesStackNavigator,
  SongsStackNavigator,
  PreferencesStackNavigator,
  FavoritesStackNavigator
} from '../stack/Stack';
//articles.svg from assets/images
import { Image } from 'react-native';

const Tab = createBottomTabNavigator<TabParamList>();

const renderTabBarIcon = (tabName: keyof TabParamList) => (tabStatus: TabBarStatus) => {
  switch (tabName) {
    case 'HomeTab':
      return <AntDesign name="home" size={26} color={tabStatus.color} />;
    case 'ArticlesTab':
      return (
        <Image
          source={require('../../../assets/images/articles.svg')}
          style={{ width: 26, height: 30, tintColor: tabStatus.color }}
        />
      );
    case 'SongsTab':
      return (
        <Image
          source={require('../../../assets/images/songs.svg')}
          style={{ width: 26, height: 26, tintColor: tabStatus.color }}
        />
      );
    case 'PreferencesTab':
      return (
        <Image
          source={require('../../../assets/images/preferences.svg')}
          style={{ width: 26, height: 26, tintColor: tabStatus.color }}
        />
      );
    // add more...
  }
};

export default function TabNavigator() {
  return (
    <Tab.Navigator
      screenOptions={({ route }) => ({
        tabBarStyle: {
          borderWidth: 0,
          backgroundColor: colors.smartPurple,
          paddingVertical: 0,
          paddingBottom: Platform.OS === 'web' ? 5 : 0,
          overflow: 'hidden',
          height: Platform.OS === 'web' ? 60 : 70,
        },
        tabBarIcon: renderTabBarIcon(route.name),
        headerShown: false,
        tabBarInactiveTintColor: 'grey',
        tabBarInactiveBackgroundColor: colors.smartPurple,
        tabBarActiveTintColor: colors.white,
        tabBarActiveBackgroundColor: colors.smartPurple,
      })}
      screenListeners={{
        tabPress: e => {
          window.dataLayer.push({ event: 'tab_press', tab: e.target?.split('-')[0] });
        },
      }}>
      <Tab.Screen name="HomeTab" component={HomeStackNavigator} options={{ title: 'Home' }} />
      <Tab.Screen
        name="ArticlesTab"
        component={ArticlesStackNavigator}
        options={{ title: 'Articles' }}
      />
      <Tab.Screen
        name="SongsTab"
        component={SongsStackNavigator}
        options={{ title: 'Song Book' }}
      />
      <Tab.Screen
        name="PreferencesTab"
        component={PreferencesStackNavigator}
        options={{ title: 'Preferences' }}
      />
      <Tab.Screen
        name="FavoritesTab"
        component={FavoritesStackNavigator}
        options={{ title: 'Favorites',tabBarButton: () => null, }}
      />
    </Tab.Navigator>
  );
}
