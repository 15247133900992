import React, { useRef } from 'react';
import { TouchableOpacity, Text, View, ScrollView, StyleSheet, StatusBar } from 'react-native';
import { AntDesign } from '@expo/vector-icons';
import { StackProps } from '@navigator/stack';
import { colors } from '@theme';
import { useAppModule } from '@modules/app.module';
import { RightDrawerContext } from './../../navigator/drawer/Drawer';
import { Button } from '@gluestack-ui/themed';

const styles = StyleSheet.create({
  root: {
    width: '100%',
    height: '100%',
    backgroundColor: colors.lightGrayPurple,
    borderTopWidth: StyleSheet.hairlineWidth,
    borderColor: colors.ltPurple,
  },
  scrollRoot: {
    width: '100%',
    height: '100%',
  },
  container: {
    width: '100%',
    maxWidth: 1200,
    padding: 22,
    paddingTop: 0,
    marginHorizontal: 'auto',
  },
  wrapper: {
    marginTop: 20,
    flexDirection: 'row',
    alignItems: 'center',
    cursor: 'pointer',
  },
  circle: {
    width: 40,
    height: 40,
    borderRadius: 20,
    backgroundColor: colors.smartPink,
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 15,
  },
  circleTitle: {
    color: colors.white,
    textAlign: 'center',
    includeFontPadding: false,
    textTransform: 'uppercase',
    fontFamily: 'Nunito_900Black',
    fontSize: 20,
    fontWeight: '900',
    lineHeight: 10,
    marginTop: 8,
  },
  circleTitleSmall: {
    color: colors.white,
    textAlign: 'center',
    includeFontPadding: false,
    textTransform: 'uppercase',
    fontFamily: 'Nunito_900Black',
    fontSize: 12,
    fontWeight: '900',
    lineHeight: 10,
    marginTop: 8,
  },
  circleSubtitle: {
    color: colors.white,
    textAlign: 'center',
    includeFontPadding: false,
    textTransform: 'uppercase',
    fontFamily: 'Nunito_900Black',
    fontSize: 7,
    fontWeight: '900',
    lineHeight: 20,
  },
  songWrapper: {
    alignItems: 'flex-end',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  songDetails: {
    flex: 1,
  },
  title: {
    fontSize: 16,
    marginBottom: 2,
    color: colors.smartPurple,
    fontFamily: 'Nunito_900Black',
    fontWeight: '900',
  },
  subtitle: {
    fontSize: 10,
    marginBottom: 5,
    color: colors.smartPurple,
    fontFamily: 'Nunito_600SemiBold',
    textTransform: 'uppercase',
    fontWeight: '900',
  },
  buttonTitle: {
    fontSize: 16,
    color: colors.white,
    textAlign: 'center',
  },
  button: {
    paddingVertical: 8,
    paddingHorizontal: 16,
    borderRadius: 5,
    backgroundColor: colors.lightPurple,
  },
  caption: {
    color: colors.smartPurple,
    includeFontPadding: false,
    textTransform: 'uppercase',
    fontFamily: 'Nunito_400Regular',
    fontSize: 12,
    fontWeight: '400',
    lineHeight: 12,
    marginTop: 10,
    marginBottom: 0,
    marginLeft: 20,
    marginRight: 20,
  },
  tagWrapper: {
    marginTop: 0,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    alignItems: 'baseline',
    alignContent: 'flex-start',
  },
  tag: {
    backgroundColor: colors.ltPurple,
    borderRadius: 4,
    color: colors.smartPurple,
    includeFontPadding: false,
    textTransform: 'uppercase',
    fontFamily: 'Nunito',
    fontSize: 12,
    fontWeight: '600',
    lineHeight: 12,
    letterSpacing: 0.72,
    padding: 5,
    paddingRight: 10,
    marginRight: 10,
    marginBottom: 5,
  },
  deleteFilterButton: {
    backgroundColor: colors.transparent,
    paddingLeft: 8,
    paddingRight: 0,
    height: 'unset',
  },
  alphabetContainer: {
    // fixed position in the right
    position: 'absolute',
    right: 0,
    top: 50,
    zIndex: 100,
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    borderTopLeftRadius: 10,
    borderBottomLeftRadius: 10,
    padding: 5,
  },
  alphabet: {
    color: colors.smartPurple,
    fontSize: 14,
    lineHeight: 18,
    textAlign: 'center',
    marginBottom: 3,
  },
  notFound: {
    color: colors.smartPurple,
    fontSize: 14,
    textAlign: 'center',
    marginTop: 20,
  },
});

export default function Songs({ navigation }: StackProps) {
  const {
    feed,
    user,
    filterSongs,
    filterSongsLevels,
    filterSongsKeyword,
    filterFavoriteSongs,
    dispatch,
    setFavoriteSongs,
    setFilterSongs,
    setFilterSongsLevels,
    setFilterSongsKeyword,
    setFilterFavoriteSongs,
  } = useAppModule();
  const scrollRef = useRef(null);
  const songs = JSON.parse(JSON.stringify(feed?.songs || []));
  const favoriteSongs = JSON.parse(JSON.stringify(user?.user?.favoriteSongs || []));
  const { openRightDrawer } = React.useContext(RightDrawerContext);

  const filteredSongs = songs?.filter(a => {
    let display = true;
    if (filterSongs?.length > 0) {
      let found = false;
      filterSongs.forEach(tag => {
        if (a?.tags?.find(t => t.id === tag.id)) found = true;
      });
      if (!found) display = false;
    }

    if (filterSongsLevels?.length > 0) {
      let found = false;
      filterSongsLevels.forEach(tag => {
        if (a?.level?.id === tag.id) found = true;
      });
      if (!found) display = false;
    }

    if (!!filterSongsKeyword && filterSongsKeyword !== '') {
      if (
        !a?.title?.toLowerCase().includes(filterSongsKeyword.toLowerCase()) &&
        !a?.subtitle?.toLowerCase().includes(filterSongsKeyword.toLowerCase())
      )
        display = false;
    }

    if (filterFavoriteSongs) {
      let found = false;
      favoriteSongs.forEach(s => {
        if (a?.id === s.id) found = true;
      });
      if (!found) display = false;
    }

    return display;
  });

  window.dataLayer.push({
    event: 'page_load',
    page: 'Song Book',
  });

  return (
    <View style={styles.root}>
      {songs?.length > 0 && (<TouchableOpacity
        onPress={() => {
          openRightDrawer('songs');
        }}>
        <View style={styles.tagWrapper}>
          <Text style={styles.caption}>FILTER</Text>
          {(filterSongs?.length > 0 ||
            filterSongsLevels?.length > 0 ||
            (!!filterSongsKeyword && filterSongsKeyword !== '') ||
            filterFavoriteSongs) && (
            <React.Fragment>
              {filterSongsLevels?.map((tag, ind) => (
                <Text key={`tag2_${ind}`} style={{ ...styles.tag, backgroundColor: colors.ltPink }}>
                  {tag.title}
                  <Button
                    style={styles.deleteFilterButton}
                    size="sm"
                    onPress={() =>
                      dispatch(
                        setFilterSongsLevels(filterSongsLevels.filter((t: any) => t.id !== tag.id)),
                      )
                    }>
                    x
                  </Button>
                </Text>
              ))}
              {filterSongs?.map((tag, ind) => (
                <Text key={`tag3_${ind}`} style={styles.tag}>
                  {tag.title}
                  <Button
                    style={styles.deleteFilterButton}
                    size="sm"
                    onPress={() =>
                      dispatch(setFilterSongs(filterSongs.filter((t: any) => t.id !== tag.id)))
                    }>
                    x
                  </Button>
                </Text>
              ))}
              {!!filterSongsKeyword && filterSongsKeyword !== '' && (
                <>
                  <Text style={{ ...styles.tag, backgroundColor: colors.ltYellow }}>
                    {filterSongsKeyword}
                    <Button
                      style={styles.deleteFilterButton}
                      size="sm"
                      onPress={() => dispatch(setFilterSongsKeyword(''))}>
                      x
                    </Button>
                  </Text>
                </>
              )}
              {filterFavoriteSongs && (
                <>
                  <Text style={{ ...styles.tag, backgroundColor: colors.ltYellow }}>
                    Favorite Songs
                    <Button
                      style={styles.deleteFilterButton}
                      size="sm"
                      onPress={() => dispatch(setFilterFavoriteSongs(false))}>
                      x
                    </Button>
                  </Text>
                </>
              )}
            </React.Fragment>
          )}
        </View>
      </TouchableOpacity>)}
      <View style={styles.alphabetContainer}>
        {[...Array(26)].map((_, i) => {
          // is no songs with this letter, skip
          if (
            filteredSongs.findIndex(song =>
              song.title.toUpperCase().startsWith(String.fromCharCode(65 + i)),
            ) === -1
          )
            return null;
          return (
            <Text
              key={`letter_${i}`}
              style={styles.alphabet}
              onPress={() => {
                const letter = String.fromCharCode(65 + i);
                const index = filteredSongs.findIndex(song =>
                  song.title.toUpperCase().startsWith(letter),
                );
                if (index !== -1) {
                  const scrollY = index * 60;
                  scrollRef.current.scrollTo({ y: scrollY });
                }
              }}>
              {String.fromCharCode(65 + i)}
            </Text>
          );
        })}
      </View>
      <ScrollView ref={scrollRef} style={styles.scrollRoot}>
        <View style={styles.container}>
          <StatusBar barStyle="light-content" />

          {filteredSongs
            .sort((a, b) => a.title.localeCompare(b.title))
            .map((song) => {
              const lvl = song.level?.levelNumber;
              const { id, title, subtitle } = song;
              const isFavorite = favoriteSongs && favoriteSongs.findIndex(s => s.id === id) !== -1;
              const colorsArray = [
                colors.smartPink,
                colors.smartOrange,
                colors.smartYellow,
                colors.smartTeal,
                colors.smartPurple,
                colors.ltPink,
                colors.ltOrange,
                colors.ltYellow,
                colors.ltTeal,
                colors.ltPurple,
              ];
              return (
                <View
                  key={`song_${song.id}`}
                  style={styles.songWrapper}
                >
                  <TouchableOpacity
                    style={styles.songDetails}
                    onPress={() => navigation.navigate('DetailsStack', { view: 'song', data: song })}>
                    <View style={styles.wrapper}>
                      <View style={{ ...styles.circle, backgroundColor: colorsArray[parseInt(lvl)] }}>
                        {lvl === "0" ? <Text style={styles.circleTitleSmall}>0-12</Text> :
                        <Text style={styles.circleTitle}>{lvl}</Text>}
                        <Text style={styles.circleSubtitle}>
                          {lvl === "0" ? 'MON' : lvl === '1' ? 'YR' : 'YRS'}
                        </Text>
                      </View>

                      <View style={styles.songDetails}>
                        <Text style={styles.title}>{title}</Text>
                        <Text style={styles.subtitle}>{subtitle}</Text>
                      </View>
                    </View>
                  </TouchableOpacity>
                  <AntDesign.Button
                    name={isFavorite ? 'heart' : 'hearto'}
                    size={24}
                    color={colors.smartPurple}
                    backgroundColor={colors.transparent}
                    onPress={() => dispatch(setFavoriteSongs(favoriteSongs, id, isFavorite))}
                  />
                </View>
              );
            })}
          {(filteredSongs.length === 0 || songs.length === 0) && <Text style={styles.notFound}>No songs found</Text>}
        </View>
      </ScrollView>
    </View>
  );
}
