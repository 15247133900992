import React, { useState } from 'react';
import {
  TouchableOpacity,
  Text,
  View,
  ScrollView,
  StyleSheet,
  StatusBar,
  useWindowDimensions,
  Platform,
} from 'react-native';
import Button from '@components/Button';
import { AntDesign } from '@expo/vector-icons';
import { StackProps } from '@navigator/stack';
import { colors } from '@theme';
import { useAppModule } from '@modules/app.module';
import { Image } from 'expo-image';
import { mediaUrl } from '@utils/mediaUrl';
import HTMLView from 'react-native-htmlview';
import AudioPlayer from '@components/AudioPlayer';
import { Video, ResizeMode } from 'expo-av';
import ImageView from 'react-native-image-view';
import Lightbox from 'react-native-lightbox-v2';
import { Vimeo } from 'react-native-vimeo-iframe';

const styles = StyleSheet.create({
  root: {
    width: '100%',
    height: '100%',
    backgroundColor: colors.lightGrayPurple,
    color: colors.smartPurple,
  },
  container: {
    width: '100%',
    maxWidth: 1200,
    padding: 22,
    paddingTop: 0,
    marginHorizontal: 'auto',
    borderTopWidth: StyleSheet.hairlineWidth,
    borderColor: colors.ltPurple,
  },
  title: {
    fontSize: 24,
    marginBottom: 20,
  },
  titleWrapper: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  buttonTitle: {
    fontSize: 16,
    color: colors.white,
    textAlign: 'center',
  },
  button: {
    paddingVertical: 8,
    paddingHorizontal: 16,
    borderRadius: 5,
    backgroundColor: colors.pink,
  },
  author: {
    color: colors.smartPurple,
    textAlign: 'left',
    includeFontPadding: false,
    fontFamily: 'Nunito_500Medium',
    fontSize: 12,
    fontWeight: '500',
    lineHeight: 12,
    marginBottom: 10,
  },
  text: {
    color: colors.smartPurple,
    textAlign: 'left',
    includeFontPadding: false,
    fontFamily: 'Nunito_500Medium',
    fontSize: 12,
    fontWeight: '500',
    lineHeight: 12,
  },
  caption: {
    color: colors.smartPurple,
    includeFontPadding: false,
    textTransform: 'uppercase',
    fontFamily: 'Nunito_400Regular',
    fontSize: 12,
    fontWeight: '400',
    lineHeight: 12,
    marginTop: 10,
  },
  articleTitle: {
    fontSize: 40,
    lineHeight: 40,
    marginBottom: 10,
    marginTop: 10,
    color: colors.smartPurple,
    includeFontPadding: false,
    fontFamily: 'Nunito_900Black',
    fontWeight: '900',
  },
  songTitle: {
    fontSize: 40,
    lineHeight: 40,
    marginBottom: 0,
    marginTop: 10,
    color: colors.smartPink,
    includeFontPadding: false,
    fontFamily: 'Nunito_900Black',
    fontWeight: '900',
  },
  songSubtitle: {
    fontSize: 18,
    marginTop: 30,
    color: colors.smartPink,
    includeFontPadding: false,
    fontFamily: 'Nunito_900Black',
    fontWeight: '900',
    lineHeight: 22,
  },
  songSubtitleBg: {
    fontSize: 18,
    marginTop: 5,
    color: colors.smartPink,
    includeFontPadding: false,
    fontFamily: 'Nunito_900Black',
    fontWeight: '900',
    lineHeight: 22,
  },
  wrapper: {
    marginTop: 20,
    marginBottom: 20,
    paddingBottom: 20,
    width: '100%',
  },
  songWrapper: {
    marginTop: 5,
    marginBottom: 20,
    paddingBottom: 20,
  },
  tag: {
    backgroundColor: colors.ltPurple,
    borderRadius: 4,
    color: colors.smartPurple,
    includeFontPadding: false,
    textTransform: 'uppercase',
    fontFamily: 'Nunito_400Regular',
    fontSize: 12,
    fontWeight: '600',
    lineHeight: 12,
    letterSpacing: 0.72,
    padding: 5,
    paddingRight: 10,
    marginRight: 10,
    marginBottom: 5,
  },
  levelTag: {
    backgroundColor: colors.ltPink,
    borderRadius: 4,
    color: colors.smartPurple,
    includeFontPadding: false,
    textTransform: 'uppercase',
    fontFamily: 'Nunito_400Regular',
    fontSize: 12,
    fontWeight: '600',
    lineHeight: 12,
    letterSpacing: 0.72,
    padding: 5,
    paddingRight: 10,
    marginRight: 10,
  },
  tagWrapper: {
    marginTop: 10,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    alignItems: 'baseline',
    alignContent: 'flex-start',
  },
  instructionsWrapper: {
    // backgroundColor: colors.smartPink,
    border: `2px solid ${colors.smartPink}`,
    borderRadius: 6,
    padding: 10,
    paddingTop: 0,
    marginTop: 15,
  },
  imgContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
});
const colorsArray = [
  colors.ltPink,
  colors.ltOrange,
  colors.ltYellow,
  colors.ltTeal,
  colors.ltPurple,
  colors.smartPink,
  colors.smartOrange,
  colors.smartYellow,
  colors.smartTeal,
  colors.smartPurple,
];

export default function Details({ navigation, route }: StackProps) {
  const {
    user,
    dispatch,
    setFavoriteArticles,
    setFavoriteSongs,
  } = useAppModule();
  const { from, view, data } = route.params;
  const img = data?.image;
  const video = mediaUrl(data?.video?.url);
  const audio = mediaUrl(data?.audio?.url);
  const vimeo_video_id = data?.vimeo_video_id;
  const { width } = useWindowDimensions();
  const mediaWidth = width > 1000 ? 1000 : width - 60;
  const imgHeight = mediaWidth * (img?.height / img?.width);
  const [openImage, setOpenImage] = useState(false);
  const favoriteArticles = JSON.parse(JSON.stringify(user?.user?.favoriteArticles || []));
  const favoriteSongs = JSON.parse(JSON.stringify(user?.user?.favoriteSongs || []));

  window.dataLayer.push({
    event: 'page_load',
    page: view === 'article' ? 'Article' : 'Song',
    title: data.title,
  });

  if (view === 'article') {
    let { id, title, author, text, tags } = data;
    const isFavorite = favoriteArticles && favoriteArticles.findIndex(s => s.id === id) !== -1;

    tags = tags?.length > 0 ? tags.map(t => t.title) : [];
    return (
      <React.Fragment>
        <ScrollView style={styles.root}>
          <View style={styles.container}>
            <View style={styles.wrapper}>
              {video && (
                <Video
                  videoStyle={{ width: mediaWidth, height: mediaWidth / 1.6 }}
                  style={{ width: mediaWidth, marginLeft: 0, height: mediaWidth / 1.6 }}
                  source={{
                    uri: video,
                  }}
                  useNativeControls
                  resizeMode={ResizeMode.CONTAIN}
                  isLooping
                />
              )}
              {vimeo_video_id && (
                <>
                  {Platform.OS === 'web' ? (
                    <div style={{ padding: '56.25% 0 0 0', margin: '10px', position: 'relative' }}>
                      <iframe
                        style={{
                          width: '100%',
                          height: '100%',
                          position: 'absolute',
                          top: '0',
                          left: '0',
                          border: '0',
                        }}
                        src={`https://player.vimeo.com/video/${vimeo_video_id}`}
                        allow="autoplay; fullscreen; picture-in-picture"></iframe>
                    </div>
                  ) : (
                    <Vimeo
                      videoId={vimeo_video_id}
                      params={'api=1&autoplay=0'}
                      //handlers={videoCallbacks}
                    />
                  )}
                </>
              )}

              {img?.url && (
                <View style={{ width: mediaWidth }}>
                  <Lightbox>
                    <Image
                      style={{ marginTop: 20, marginLeft: 0, height: imgHeight }}
                      resizeMode="center"
                      source={{ uri: mediaUrl(img?.url) }}
                    />
                  </Lightbox>
                </View>
              )}

              {tags.length > 0 && (
                <View style={styles.tagWrapper}>
                  {tags.map((tag, index) => {
                    return (
                      <Text key={`tag1_${index}`} style={styles.tag}>
                        {tag}
                      </Text>
                    );
                  })}
                </View>
              )}
              <View style={styles.titleWrapper}>
                <Text style={styles.articleTitle}>{title}</Text>
                <AntDesign.Button
                  name={isFavorite ? 'heart' : 'hearto'}
                  size={24}
                  color={colors.smartPurple}
                  backgroundColor={colors.transparent}
                  onPress={() => dispatch(setFavoriteArticles(favoriteArticles, id, isFavorite))}
                />
              </View>
              {author && <Text style={styles.author}>{`by ${author}`}</Text>}
              {text !== null && <HTMLView value={text} />}
            </View>
          </View>
        </ScrollView>
      </React.Fragment>
    );
  }
  if (view === 'song') {
    let {
      id,
      title,
      subtitle,
      tags,
      description,
      origin,
      instructions,
      musicLearning,
      cognitiveSkills,
      level,
    } = data;
    const isFavorite = favoriteSongs && favoriteSongs.findIndex(s => s.id === id) !== -1;

    let tagTitles = tags?.length > 0 ? tags.map(t => t.title) : [];
    return (
      <React.Fragment>
        <ScrollView style={styles.root}>
          <View style={styles.container}>
            <View style={styles.songWrapper}>
              {(level || tags?.length > 0) && (
                <View style={styles.tagWrapper}>
                  {level && (
                    <Text
                      style={{
                        ...styles.levelTag,
                        backgroundColor: colorsArray[parseInt(level.levelNumber)],
                      }}>
                      {level?.title}
                    </Text>
                  )}
                  {tagTitles.map((tag, index) => {
                    return (
                      <Text key={`tag2_${index}`} style={styles.tag}>
                        {tag}
                      </Text>
                    );
                  })}
                </View>
              )}
              <View style={styles.titleWrapper}>
                <Text style={styles.songTitle}>{title}</Text>
                <AntDesign.Button
                  name={isFavorite ? 'heart' : 'hearto'}
                  size={24}
                  color={colors.smartPurple}
                  backgroundColor={colors.transparent}
                  onPress={() => dispatch(setFavoriteSongs(favoriteSongs, id, isFavorite))}
                />
              </View>
              {subtitle && <Text style={{ ...styles.songSubtitle, marginTop: 0 }}>{subtitle}</Text>}
              {video && (
                <View
                  style={{ flex: 1, marginTop: 30, alignItems: 'center', justifyContent: 'center' }}>
                  <Video
                    videoStyle={{ marginTop: 10, height: mediaWidth / 1.77 }}
                    style={{
                      width: mediaWidth,
                      height: mediaWidth / 1.77,
                    }}
                    source={{
                      uri: video,
                    }}
                    useNativeControls
                    resizeMode={ResizeMode.CONTAIN}
                    isLooping
                  />
                </View>
              )}
              {vimeo_video_id && (
                <>
                  {Platform.OS === 'web' ? (
                    <div style={{ padding: '56.25% 0 0 0', margin: '10px', position: 'relative' }}>
                      <iframe
                        style={{
                          width: '100%',
                          height: '100%',
                          position: 'absolute',
                          top: '0',
                          left: '0',
                          border: '0',
                        }}
                        src={`https://player.vimeo.com/video/${vimeo_video_id}`}
                        allow="autoplay; fullscreen; picture-in-picture"></iframe>
                    </div>
                  ) : (
                    <Vimeo
                      videoId={vimeo_video_id}
                      params={'api=1&autoplay=0'}
                      //handlers={videoCallbacks}
                    />
                  )}
                </>
              )}

              {img?.url && (
                <Lightbox>
                  <Image
                    style={{ marginTop: 20, height: imgHeight }}
                    resizeMode="center"
                    source={{ uri: mediaUrl(img?.url) }}
                  />
                </Lightbox>
              )}

              {audio && <AudioPlayer audio={audio} fullWidth={true} songId={id} songTitle={title} />}
              {origin && (
                <HTMLView
                  textComponentProps={{ style: { color: colors.smartPurple } }}
                  value={`<div><b>Origin:</b> ${origin}</div>`}
                />
              )}
              {description && (
                <View>
                  <Text style={styles.songSubtitle}>Description</Text>
                  <HTMLView
                    textComponentProps={{ style: { color: colors.smartPurple } }}
                    value={description}
                  />
                </View>
              )}
              {tags?.find((t: any) => t.tag_category?.title === 'Instrument type') && (
                <View>
                  <Text style={styles.songSubtitle}>Instruments</Text>
                  {tags
                    ?.filter((t: any) => t.tag_category?.title === 'Instrument type')
                    .map((t: any, i: number) => {
                      return <Text
                        key={`tag3_${i}`}
                        style={{ color: colors.smartPurple }}
                      >{t.title}</Text>;
                    })}
                </View>
              )}
              {instructions && (
                <View style={styles.instructionsWrapper}>
                  <Text style={styles.songSubtitleBg}>Instructions</Text>
                  <HTMLView
                    textComponentProps={{ style: { color: colors.smartPurple } }}
                    value={instructions}
                  />
                </View>
              )}
              {musicLearning && (
                <View>
                  <Text style={styles.songSubtitle}>Music Learning</Text>
                  <HTMLView
                    textComponentProps={{ style: { color: colors.smartPurple } }}
                    value={musicLearning}
                  />
                </View>
              )}
              {cognitiveSkills && (
                <View>
                  <Text style={styles.songSubtitle}>Cognitive Skills</Text>
                  <HTMLView
                    textComponentProps={{ style: { color: colors.smartPurple } }}
                    value={cognitiveSkills}
                  />
                </View>
              )}
            </View>
          </View>
        </ScrollView>
      </React.Fragment>
    );
  }

  return (
    <ScrollView style={styles.root}>
      <View style={styles.container}>
        <StatusBar barStyle="light-content" />
        <Text style={styles.title}>{`Details (from ${from})`}</Text>
        <Button
          title="Go back"
          titleStyle={styles.buttonTitle}
          style={styles.button}
          onPress={() => {
            navigation.goBack();
          }}
        />
      </View>
    </ScrollView>
  );
}
