import React from 'react';
import { AntDesign } from '@expo/vector-icons';
import {
  ImageBackground,
  Text,
  View,
  StyleSheet,
  ScrollView,
  StatusBar,
  useWindowDimensions,
  Touchable,
} from 'react-native';
// import Button from '@components/Button';
import { Image } from 'expo-image';
import { colors } from '@theme';
import { useAppModule } from '@modules/app.module';
import { mediaUrl } from '@utils/mediaUrl';
import { TouchableOpacity } from 'react-native-gesture-handler';

const stylesWhite = StyleSheet.create({
  author: {
    color: colors.smartPurple,
    textAlign: 'left',
    includeFontPadding: false,
    fontFamily: 'Nunito_500Medium',
    fontSize: 14,
    fontWeight: '500',
    lineHeight: 14,
    marginBottom: 10,
  },
  titleWrapper: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  title: {
    fontSize: 22,
    marginBottom: 10,
    marginTop: 10,
    color: colors.smartPurple,
    includeFontPadding: false,
    fontFamily: 'Nunito_900Black',
    fontWeight: '900',
    lineHeight: 22,
  },
  subtitle: {
    fontSize: 14,
    marginRight: 10,
    color: colors.smartPurple,
    includeFontPadding: false,
    textTransform: 'uppercase',
    fontFamily: 'Nunito_900Black',
    fontWeight: '900',
  },
  text: {
    color: colors.smartPurple,
    textAlign: 'left',
    includeFontPadding: false,
    fontFamily: 'Nunito_500Medium',
    fontSize: 14,
    fontWeight: '500',
    lineHeight: 16,
  },
  wrapper: {
    marginTop: 5,
    marginBottom: 20,
    paddingBottom: 20,
    paddingTop:15,
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderColor: colors.ltPurple,
  },

  readMore: {
    color: colors.smartPurple,
    marginTop: 10,
    marginBottom: 20,
    textAlign: 'left',
    includeFontPadding: false,
    textTransform: 'uppercase',
    fontFamily: 'Nunito_900Black',
    fontSize: 12,
    fontWeight: '900',
    lineHeight: 12,
  },
  tag: {
    backgroundColor: colors.ltPurple,
    borderRadius: 4,
    color: colors.smartPurple,
    includeFontPadding: false,
    textTransform: 'uppercase',
    fontFamily: 'Nunito_400Regular',
    fontSize: 12,
    fontWeight: '600',
    lineHeight: 12,
    letterSpacing: 0.72,
    padding: 5,
    paddingRight: 10,
    marginRight: 10,
    marginBottom: 5,
  },
  tagWrapper: {
    marginTop: 10,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    alignItems: 'baseline',
    alignContent: 'flex-start',
  },
  img: {
    width: 357,
    height: 222,
    marginRight: 30,
    cursor: 'pointer',
  },
  flex: {
    flex: 1,
  },
  pointer: {
    cursor: 'pointer',
  }
});

const stylesDark = StyleSheet.create({
  author: {
    color: 'white',
    textAlign: 'left',
    includeFontPadding: false,
    fontFamily: 'Nunito_500Medium',
    fontSize: 12,
    fontWeight: '500',
    lineHeight: 12,
    marginBottom: 10,
  },
  titleWrapper: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  title: {
    fontSize: 22,
    marginBottom: 10,
    marginTop: 10,
    color: 'white',
    includeFontPadding: false,
    fontFamily: 'Nunito_900Black',
    fontWeight: '900',
    lineHeight: 22,
  },
  subtitle: {
    fontSize: 12,
    marginRight: 10,
    color: 'white',
    includeFontPadding: false,
    textTransform: 'uppercase',
    fontFamily: 'Nunito_900Black',
    fontWeight: '900',
  },
  text: {
    color: 'white',
    textAlign: 'left',
    includeFontPadding: false,
    fontFamily: 'Nunito_500Medium',
    fontSize: 14,
    fontWeight: '500',
  },
  wrapper: {
    marginTop: 5,
    marginBottom: 20,
    paddingBottom: 20,
    paddingTop:15,
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderColor: colors.ltPurple,
  },

  readMore: {
    color: colors.ltPurple,
    marginTop: 10,
    marginBottom: 20,
    textAlign: 'left',
    includeFontPadding: false,
    textTransform: 'uppercase',
    fontFamily: 'Nunito_900Black',
    fontSize: 12,
    fontWeight: '900',
    lineHeight: 12,
    cursor: 'pointer'
  },
  tag: {
    backgroundColor: colors.ltPurple,
    borderRadius: 4,
    color: colors.smartPurple,
    includeFontPadding: false,
    textTransform: 'uppercase',
    fontFamily: 'Nunito_400Regular',
    fontSize: 12,
    fontWeight: '600',
    lineHeight: 12,
    letterSpacing: 0.72,
    padding: 5,
    paddingRight: 10,
    marginRight: 10,
    marginBottom: 5,
  },
  tagWrapper: {
    marginTop: 10,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    alignItems: 'baseline',
    alignContent: 'flex-start',
  },
  img: {
    width: 357,
    height: 222,
    cursor: 'pointer',
    marginRight: 30,
  },
  flex: {
    flex: 1,
  },
  pointer: {
    cursor: 'pointer',
  }
});

export default function ArticleListItem(props) {
  const {
    user,
    dispatch,
    setFavoriteArticles,
  } = useAppModule();
  const favoriteArticles = JSON.parse(JSON.stringify(user?.user?.favoriteArticles || []));
  const { navigation, article, subtitle, theme = 'white', showFavorite = false } = props;
  const { width } = useWindowDimensions();
  const styles = theme === 'dark' ? stylesDark : stylesWhite;
  
  if (!article) return null;
  let { id, title, author, text, tags, shortText } = article;
  const isFavorite = favoriteArticles && favoriteArticles.findIndex(s => s.id === id) !== -1;
  tags = tags?.length > 0 ? tags.map(t => t.title) : [];
  if (!shortText) {
    //remove all html from text
    shortText = text.replace(/(<([^>]+)>)/gi, '');
    //make it 200 symbols, cut off the last word
    shortText = shortText.substring(0, 200);
    shortText = shortText.substring(0, shortText.lastIndexOf(' '));
  }

  const img = mediaUrl(article.image?.thumbnail_url);

  const articleOnClick = () => {
    navigation.navigate('DetailsStack', { view: 'article', data: article });
  };

  return (
    <View key={`article_${article.id}`}  style={{...styles.wrapper, marginTop: props.isFirst ? 0 : 5,  paddingTop: props.isFirst ? 0 : 15 }}>
      <TouchableOpacity onPress={articleOnClick}>
        <View>
          {img && <Image style={{ ...styles.img, maxWidth: width - 40 }} source={img} />}
        </View>
      </TouchableOpacity>

      {tags.length > 0 && (
        <ScrollView
          showsHorizontalScrollIndicator={false}
          horizontal
          contentContainerStyle={styles.tagWrapper}
        >
          {subtitle ? <Text style={styles.subtitle}>Article</Text> : null}
          {tags.map((tag, index) => {
            return (
              <Text key={index} style={styles.tag}>
                {tag}
              </Text>
            );
          })}
        </ScrollView>
      )}

      <View>
        <View style={styles.titleWrapper}>
          <TouchableOpacity
            containerStyle={{...styles.flex, ...styles.pointer}}
            onPress={articleOnClick}
          >
            <Text style={{...styles.title, lineHeight: theme==='home'?35:40, fontSize: theme==='home'?30:40 }}>{title}</Text>
          </TouchableOpacity>
          {showFavorite && (
            <AntDesign.Button
              name={isFavorite ? 'heart' : 'hearto'}
              size={24}
              color={colors.smartPurple}
              backgroundColor={colors.transparent}
              onPress={() => dispatch(setFavoriteArticles(favoriteArticles, id, isFavorite))}
            />
          )}
        </View>
        <TouchableOpacity
          style={styles.pointer}
          onPress={articleOnClick}
        >
          {author && <Text style={styles.author}>{`by ${author}`}</Text>}
          {shortText && <Text style={styles.text}>{shortText}</Text>}
          {shortText && <Text style={styles.readMore}>READ MORE</Text>}
        </TouchableOpacity>
      </View>
    </View>
  );
}
