import { useDispatch, useSelector } from 'react-redux';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { State, Dispatch } from '@utils/store';
import { storage } from '@utils/storage';
import { cmsAPI } from "@utils/cmsApi";
interface IAppUser {
  name: string;
  email: string;
}

interface IAppFeed {
  songs: any[];
  articles: any[];
  categories: any[];
  levels: any[];
  tags: any[];
  articleTags: any[];
  songTags: any[];

}

interface IAppState {
  loading: boolean;
  loggedIn: boolean;
  user?: any;
  feed?: IAppFeed;
  filterArticles: any[],
  filterSongs: any[],
  filterSongsLevels: any[],
  filterArticlesKeyword: string,
  filterSongsKeyword: string,
  filterFavoriteArticles: boolean,
  filterFavoriteSongs: boolean,
}



const initialState: IAppState = {
  loading: true,
  loggedIn: false,
  user: undefined,
  filterArticles: [],
  filterSongs: [],
  filterSongsLevels: [],
  filterArticlesKeyword: '',
  filterSongsKeyword: '',
  filterFavoriteArticles: false,
  filterFavoriteSongs: false,
};

const slice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    //SET_USER_LEVEL
    SET_USER_LEVEL: (state: IAppState, { payload }: PayloadAction<{ level: number }>) => {
      //set storage
      storage.set('user', JSON.stringify({
        ...state.user,
        user: {
          ...state.user?.user,
          level: payload.level
        }
      }));
      state.user = {
        ...state.user,
        user: {
          ...state.user?.user,
          level: payload.level
        }
      }
    },
    //SET_FAVORITE_ARTICLES
    SET_FAVORITE_ARTICLES: (state: IAppState, { payload }: PayloadAction<{ favoriteArticles: any[] }>) => {
      //set storage
      storage.set('user', JSON.stringify({
        ...state.user,
        user: {
          ...state.user?.user,
          favoriteArticles: payload.favoriteArticles
        }
      }));
      state.user = {
        ...state.user,
        user: {
          ...state.user?.user,
          favoriteArticles: payload.favoriteArticles
        }
      }
    },
    //SET_FAVORITE_SONGS
    SET_FAVORITE_SONGS: (state: IAppState, { payload }: PayloadAction<{ favoriteSongs: any[] }>) => {
      //set storage
      storage.set('user', JSON.stringify({
        ...state.user,
        user: {
          ...state.user?.user,
          favoriteSongs: payload.favoriteSongs
        }
      }));
      state.user = {
        ...state.user,
        user: {
          ...state.user?.user,
          favoriteSongs: payload.favoriteSongs
        }
      }
    },
    // SET_REDEMPTION_CODE
    SET_REDEMPTION_CODE: (state: IAppState, { payload }: PayloadAction<{ code: string }>) => {
      //set storage
      storage.set('user', JSON.stringify({
        ...state.user,
        user: {
          ...state.user?.user,
          redemptionCode: payload.code
        }
      }));
      state.user = {
        ...state.user,
        user: {
          ...state.user?.user,
          redemptionCode: payload.code
        }
      }
    },
    SET_REDEMPTION_CODE_EXPIRATION: (state: IAppState, { payload }: PayloadAction<{ redemptionCodeExpirationTimestamp: number }>) => {
      //set storage
      storage.set('user', JSON.stringify({
        ...state.user,
        user: {
          ...state.user?.user,
          redemptionCodeExpirationTimestamp: payload.redemptionCodeExpirationTimestamp
        }
      }));
      state.user = {
        ...state.user,
        user: {
          ...state.user?.user,
          redemptionCodeExpirationTimestamp: payload.redemptionCodeExpirationTimestamp
        }
      }
    },
    authenticate: (
      state: IAppState,
      { payload }: PayloadAction<{ loggedIn: boolean; user?: any }>,
    ) => {
      state.loggedIn = payload.loggedIn;
      state.loading = false;
      state.user = payload.user;
    },
    LOGOUT_ACTION: (state: IAppState) => {
      // Reset the state to its initial state
      state.loggedIn = false;
      state.user = false;
    },
    //LOAD feed
    loadFeed: (
      state: IAppState,
      { payload }: PayloadAction<{ feed: IAppFeed }>,
    ) => {
      state.feed = payload.feed;
      state.loading = false;
    },
    setFilterArticles: (
      state: IAppState,
      { payload }: PayloadAction<{ filterArticles: any[] }>,
    ) => {
      state.filterArticles = payload.filterArticles;
    },
    setFilterSongs: (
      state: IAppState,
      { payload }: PayloadAction<{ filterSongs: any[] }>,
    ) => {
      state.filterSongs = payload.filterSongs;
    },
    setFilterSongsLevels: (
      state: IAppState,
      { payload }: PayloadAction<{ filterSongsLevels: any[] }>,
    ) => {
      state.filterSongsLevels = payload.filterSongsLevels;
    },
    setFilterArticlesKeyword: (
      state: IAppState,
      { payload }: PayloadAction<{ filterArticlesKeyword: string }>,
    ) => {
      state.filterArticlesKeyword = payload.filterArticlesKeyword;
    },
    setFilterSongsKeyword: (
      state: IAppState,
      { payload }: PayloadAction<{ filterSongsKeyword: string }>,
    ) => {
      state.filterSongsKeyword = payload.filterSongsKeyword;
    },
    setFilterFavoriteArticles: (
      state: IAppState,
      { payload }: PayloadAction<{ filterFavoriteArticles: boolean }>,
    ) => {
      state.filterFavoriteArticles = payload.filterFavoriteArticles;
    },
    setFilterFavoriteSongs: (
      state: IAppState,
      { payload }: PayloadAction<{ filterFavoriteSongs: boolean }>,
    ) => {
      state.filterFavoriteSongs = payload.filterFavoriteSongs;
    },
    setLoading: (
      state: IAppState,
      { payload }: PayloadAction<{ loading: boolean }>,
    ) => {
      state.loading = payload.loading;
    },

    reset: () => initialState,
  },
});

const asyncActions = {
  setFavoriteArticles: (favoriteArticles: object[], id: number, isFavorite: boolean) => async (dispatch: Dispatch) => {
    if (isFavorite) {
      favoriteArticles.splice(favoriteArticles.findIndex((s: any) => s.id == id), 1)
    } else {
      favoriteArticles.push({ id })
    }
    try {
      await cmsAPI.patch(`${process.env.EXPO_PUBLIC_CMS}/api/smartstart/user`, {
        data: { favoriteArticles },
      });
      dispatch(
        slice.actions.SET_FAVORITE_ARTICLES({ favoriteArticles })
      )
    } catch (err) {
      console.log('[##] err', err);
    }
  },
  setFavoriteSongs: (favoriteSongs: object[], id: number, isFavorite: boolean) => async (dispatch: Dispatch) => {
    if (isFavorite) {
      favoriteSongs.splice(favoriteSongs.findIndex((s: any) => s.id == id), 1)
    } else {
      favoriteSongs.push({ id })
    }
    try {
      await cmsAPI.patch(`${process.env.EXPO_PUBLIC_CMS}/api/smartstart/user`, {
        data: { favoriteSongs },
      });
      dispatch(
        slice.actions.SET_FAVORITE_SONGS({ favoriteSongs })
      )
    } catch (err) {
      console.log('[##] err', err);
    }
  },
  setFilterArticles: (filter: any) => async (dispatch: Dispatch) => {
    try {
      dispatch(
        slice.actions.setFilterArticles({
          filterArticles: filter,
        }),
      );
    } catch (err) {
      console.log('[##] err', err);
    }
  },
  setFilterSongs: (filter: any) => async (dispatch: Dispatch) => {
    try {
      dispatch(
        slice.actions.setFilterSongs({
          filterSongs: filter,
        }),
      );
    } catch (err) {
      console.log('[##] err', err);
    }
  },
  setFilterSongsLevels: (filter: any) => async (dispatch: Dispatch) => {
    try {
      dispatch(
        slice.actions.setFilterSongsLevels({
          filterSongsLevels: filter,
        }),
      );
    } catch (err) {
      console.log('[##] err', err);
    }
  },
  setFilterArticlesKeyword: (filter: any) => async (dispatch: Dispatch) => {
    try {
      dispatch(
        slice.actions.setFilterArticlesKeyword({
          filterArticlesKeyword: filter,
        }),
      );
    } catch (err) {
      console.log('[##] err', err);
    }
  },
  setFilterSongsKeyword: (filter: any) => async (dispatch: Dispatch) => {
    try {
      dispatch(
        slice.actions.setFilterSongsKeyword({
          filterSongsKeyword: filter,
        }),
      );
    } catch (err) {
      console.log('[##] err', err);
    }
  },
  setFilterFavoriteArticles: (filter: boolean) => async (dispatch: Dispatch) => {
    try {
      dispatch(
        slice.actions.setFilterFavoriteArticles({
          filterFavoriteArticles: filter,
        }),
      );
    } catch (err) {
      console.log('[##] err', err);
    }
  },
  setFilterFavoriteSongs: (filter: boolean) => async (dispatch: Dispatch) => {
    try {
      dispatch(
        slice.actions.setFilterFavoriteSongs({
          filterFavoriteSongs: filter,
        }),
      );
    } catch (err) {
      console.log('[##] err', err);
    }
  },
  loadUser: () => async (dispatch: Dispatch) => {
    try {
      // load user from storage
      const user = await storage.get('user');
      if (!user || !JSON.parse(user)?.jwt) {
        dispatch(
          slice.actions.authenticate({
            loggedIn: false,
            user: null
          }),
        );
      } else {
        // update user
        dispatch(
          slice.actions.authenticate({
            loggedIn: true,
            user: JSON.parse(user)
          }),
        );
      }
    } catch (err) {
      console.log('[##] err', err);
    }
  },
  loginUser: (data: any) => async (dispatch: Dispatch) => {
    try {
      dispatch(
        slice.actions.authenticate({
          loggedIn: true,
          user: data,
        }),
      );
      await storage.set('user', JSON.stringify(data));
    } catch (err) {
      console.log('[##] err', err);
    }
  },
  logoutUser: () => async (dispatch: Dispatch) => {
    try {
      dispatch(
        slice.actions.authenticate({
          loggedIn: false,
          user: null,
        }),
      );
      await storage.delete('user');
    } catch (err) {
      console.log('[##] err', err);
    }
  },
  loadFeed: () => async (dispatch: Dispatch) => {
    //if not authenticated, return
    if (!storage.get('user')) {
      return;
    }

    dispatch(
      slice.actions.setLoading({loading: true}),
    );

    try {
      const [levels, articles, songs, categories, tags] = await Promise.all([
        cmsAPI.get(`${process.env.EXPO_PUBLIC_CMS}/api/levels`),
        cmsAPI.get(`${process.env.EXPO_PUBLIC_CMS}/api/articles`),
        cmsAPI.get(`${process.env.EXPO_PUBLIC_CMS}/api/songs`),
        cmsAPI.get(`${process.env.EXPO_PUBLIC_CMS}/api/article-categories`),
        cmsAPI.get(`${process.env.EXPO_PUBLIC_CMS}/api/tags`)
      ]);

      const articleTags = articles?.data?.reduce((acc: any, article: any) => {
        return [...acc, ...article?.tags?.map((tag: any) => tag?.id)];
      }, []).filter((v: any, i: any, a: any) => a.indexOf(v) === i);

      const articleTagsGrouped = articleTags?.reduce((acc: any, tag: any) => {
        const tagData = tags?.data?.data?.find((t: any) => t?.id === tag);
        const categoryData = tagData?.attributes?.tag_category;
        const category = parseInt(categoryData?.data?.attributes?.order) * 100000 + categoryData?.data?.id || categoryData?.data?.id;
        const tagTitle = tagData?.attributes?.title;
        const tagId = tagData?.id;
        if (!acc[category]) {
          acc[category] = {
            title: categoryData?.data?.attributes?.title,
            tags: [],
          };
        }
        acc[category].tags.push({
          title: tagTitle,
          id: tagId,
        });
        return acc;
      }, {});

      const songTags = songs?.data?.reduce((acc: any, song: any) => {
        return [...acc, ...song?.tags?.map((tag: any) => tag?.id)];
      }, []).filter((v: any, i: any, a: any) => a.indexOf(v) === i);

      const songTagsGrouped = songTags?.reduce((acc: any, tag: any) => {
        const tagData = tags?.data?.data?.find((t: any) => t?.id === tag);
        const categoryData = tagData?.attributes?.tag_category;
        const category = parseInt(categoryData?.data?.attributes?.order) * 100000 + categoryData?.data?.id || categoryData?.data?.id;
        const tagTitle = tagData?.attributes?.title;
        const tagId = tagData?.id;
        if (!acc[category]) {
          acc[category] = {
            title: categoryData?.data?.attributes?.title,
            tags: [],
          };
        }
        acc[category].tags.push({
          title: tagTitle,
          id: tagId,
        });
        return acc;
      }, {});

      // update
      dispatch(
        slice.actions.loadFeed({
          feed: {
            songs: songs?.data,
            articles: articles?.data,
            categories: categories?.data?.data,
            levels: levels?.data.sort((a: any, b: any) => a?.levelNumber - b?.levelNumber),
            tags: tags?.data?.data,
            articleTags: articleTagsGrouped,
            songTags: songTagsGrouped
          },
        }),
      );
    } catch (err) {
      console.log('[##] err', err);
      dispatch(
        slice.actions.setLoading({loading: false}),
      );
    }
  },
};

export const {
  LOGOUT_ACTION,
  SET_USER_LEVEL,
  SET_REDEMPTION_CODE,
  SET_REDEMPTION_CODE_EXPIRATION
} = slice.actions;

export function useAppModule() {
  const dispatch = useDispatch<Dispatch>();
  const state = useSelector(({ app }: State) => app);
  return { dispatch, ...state, ...slice.actions, ...asyncActions };
}

export default slice.reducer;
