import { colors } from "@theme";
import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  root: {
    width: '100%',
    height: '100%',
    backgroundColor: colors.smartPurple,
  },
  container: {
    width: '100%',
    maxWidth: 600,
    padding: 22,
    marginHorizontal: 'auto',
    backgroundColor: colors.smartPurple,
    borderTopWidth: StyleSheet.hairlineWidth,
    borderColor: colors.ltPurple,
  },
  title: {
    color: colors.white,
    includeFontPadding: false,
    textTransform: 'uppercase',
    fontFamily: 'Nunito_900Black',
    fontSize: 16,
    fontWeight: '900',
    lineHeight: 16,
    letterSpacing: 1.92,
  },
  buttonTitle: {
    fontSize: 16,
    color: colors.white,
    textAlign: 'center',
  },
  button: {
    paddingVertical: 8,
    paddingHorizontal: 16,
    borderRadius: 5,
    backgroundColor: colors.lightPurple,
    marginTop: 32,
  },
  input: {
    height: 40,
    padding: 10,
    borderWidth: 1,
    backgroundColor: colors.white,
    borderRadius: 6,
    borderStyle: 'none',
    placeholderTextColor: colors.ltPurple,
  },
});