import React from 'react';
import { ImageBackground, Text, View, StyleSheet, ScrollView, StatusBar } from 'react-native';
import { AntDesign } from '@expo/vector-icons';
// import Button from '@components/Button';
import { Image } from 'expo-image';
import { colors } from '@theme';
import { useAppModule } from '@modules/app.module';
import { mediaUrl } from '@utils/mediaUrl';
import AudioPlayer from '@components/AudioPlayer';
import { TouchableOpacity } from 'react-native-gesture-handler';
import HTMLView from 'react-native-htmlview';

const colorsArray = [
  colors.ltPink,
  colors.ltOrange,
  colors.ltYellow,
  colors.ltTeal,
  colors.ltPurple,
  colors.smartPink,
  colors.smartOrange,
  colors.smartYellow,
  colors.smartTeal,
  colors.smartPurple,
];
const styles = StyleSheet.create({
  root: {
    width: '100%',
    height: '100%',
    backgroundColor: colors.lightGrayPurple,
    color: colors.smartPurple,
  },
  container: {
    flex: 1,
    paddingTop: 40,
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'flex-start',
    borderTopWidth: StyleSheet.hairlineWidth,
    borderColor: colors.ltPurple,
  },
  titleWrapper: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  songTitle: {
    fontSize: 30,
    marginBottom: 0,
    marginTop: 10,
    color: colors.smartPurple,
    includeFontPadding: false,
    fontFamily: 'Nunito_900Black',
    fontWeight: '900',
    lineHeight: 35,
    cursor: 'pointer',
  },
  subtitle: {
    fontSize: 12,
    marginRight: 10,
    color: colors.smartPurple,
    includeFontPadding: false,
    textTransform: 'uppercase',
    fontFamily: 'Nunito_900Black',
    fontWeight: '900',
  },

  wrapper: {
    marginTop: 5,
    marginBottom: 20,
    paddingBottom: 20,
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderColor: colors.ltPurple,
  },

  levelTag: {
    backgroundColor: colors.ltPink,
    borderRadius: 4,
    color: colors.smartPurple,
    includeFontPadding: false,
    textTransform: 'uppercase',
    fontFamily: 'Nunito_400Regular',
    fontSize: 12,
    fontWeight: '600',
    lineHeight: 12,
    letterSpacing: 0.72,
    padding: 5,
    paddingRight: 10,
    marginRight: 10,
    marginBottom: 5,
  },
  tag: {
    backgroundColor: colors.ltPurple,
    borderRadius: 4,
    color: colors.smartPurple,
    includeFontPadding: false,
    textTransform: 'uppercase',
    fontFamily: 'Nunito_400Regular',
    fontSize: 12,
    fontWeight: '600',
    lineHeight: 12,
    letterSpacing: 0.72,
    padding: 5,
    paddingRight: 10,
    marginRight: 10,
    marginBottom: 5,
  },
  tagWrapper: {
    marginTop: 10,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    alignItems: 'baseline',
    alignContent: 'flex-start',
    cursor: 'pointer',
  },
  readMore: {
    color: colors.darkPurple,
    marginTop: 10,
    marginBottom: 20,
    textAlign: 'left',
    includeFontPadding: false,
    textTransform: 'uppercase',
    fontFamily: 'Nunito_900Black',
    fontSize: 12,
    fontWeight: '900',
    cursor: 'pointer',
    lineHeight: 12,
  },
  flex: {
    flex: 1,
  },
  pointer: {
    cursor: 'pointer',
  }
});

export default function SongListItem(props) {
  const {
    user,
    dispatch,
    setFavoriteSongs,
  } = useAppModule();
  const favoriteSongs = JSON.parse(JSON.stringify(user?.user?.favoriteSongs || []));
  const { navigation, color, subtitle, song, showFavorite = false } = props;
  if (!song) return null;
  let { id, title, tags, level, description, origin } = song;
  const isFavorite = favoriteSongs && favoriteSongs.findIndex(s => s.id === id) !== -1;
  const audio = mediaUrl(song?.audio?.url);
  tags = tags?.length > 0 ? tags?.map(t => t.title) : [];

  const songOnClick = () => {
    navigation.navigate('DetailsStack', { view: 'song', data: song });
  };

  return (
    <View style={styles.wrapper}>
      {(level || tags.length > 0) && (
        <ScrollView
          showsHorizontalScrollIndicator={false}
          horizontal
          contentContainerStyle={styles.tagWrapper}
        >
          {subtitle ? <Text style={styles.subtitle}>Song</Text> : null}
          <Text
            style={{
              ...styles.levelTag,
              backgroundColor: colorsArray[parseInt(level?.levelNumber)],
            }}>
            {level?.title}
          </Text>
          {tags.map((tag, index) => {
            return (
              <Text key={index} style={styles.tag}>
                {tag}
              </Text>
            );
          })}
        </ScrollView>
      )}
      <View>
        <View style={styles.titleWrapper}>
          <TouchableOpacity 
            containerStyle={{...styles.flex, ...styles.pointer}}
            onPress={songOnClick}
          >
            <Text style={styles.songTitle}>{title}</Text>
          </TouchableOpacity>
          {showFavorite && (
            <AntDesign.Button
              name={isFavorite ? 'heart' : 'hearto'}
              size={24}
              color={colors.smartPurple}
              backgroundColor={colors.transparent}
              onPress={() => dispatch(setFavoriteSongs(favoriteSongs, id, isFavorite))}
            />
          )}
        </View>
        {origin && (
          <HTMLView
            textComponentProps={{ style: { color: colors.smartPurple } }}
            style={{ marginTop: 10 }}
            value={`<div><b>Origin:</b> ${origin}</div>`}
          />
        )}
        {description && (
          <View>
            <HTMLView textComponentProps={{ style: { color: colors.smartPurple } }} value={description} />
          </View>
        )}
        {audio && (
          <AudioPlayer
            onClick={songOnClick}
            fullWidth={true}
            color={color}
            audio={audio}
            songId={id}
            songTitle={title}
          />
        )}
        {
          <TouchableOpacity onPress={songOnClick}>
            <Text style={styles.readMore}>LEARN MORE</Text>
          </TouchableOpacity>
        }
      </View>
    </View>
  );
}
