import axios from 'axios';
import { storage } from '@utils/storage';
import { LOGOUT_ACTION, SET_REDEMPTION_CODE_EXPIRATION } from '@modules/app.module';

var Buffer = require('buffer/').Buffer;
function atob(str) {
  return Buffer.from(str, 'base64').toString('binary');
}

let store;

const getStore = async () => {
  if (!store) {
    store = (await import('./store')).default;
  }
  return store;
};

const isExpired = token => {
  const currentTime = new Date();
  const jwtTokenExpiry = new Date(JSON.parse(atob(token.split('.')[1])).exp * 1000).getTime();
  return jwtTokenExpiry - currentTime < 5 * 60 * 1000;
};

const cmsAPI = axios.create({
  baseURL: process.env.EXPO_PUBLIC_CMS,
  headers: {},
});

cmsAPI.interceptors.request.use(
  async config => {
    try {
      const userData = await storage.get('user');
      if (userData === null) return config;
      const user = JSON.parse(userData);
      const token = user?.jwt;
      if (token) {
        if (isExpired(token)) {
          await storage.delete('user');
          if (!store) {
            store = await getStore();
            if (store) {
              setTimeout(() => store?.dispatch({ type: LOGOUT_ACTION }), 1);
            }
          }
          throw new Error('Token expired');
        } else config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    } catch (error) {
      return config;
    }
  },
  error => {
    Promise.reject(error);
  },
);

cmsAPI.interceptors.response.use(
  config => {
    return config;
  },
  async error => {
    console.log('err', error);
    const status = error.response?.status || 500;
    if (status === 401) {
      await storage.delete('user');
      if (!store) {
        store = await getStore();
        if (store) {
          setTimeout(() => store?.dispatch({ type: LOGOUT_ACTION }), 1);
        }
      }
    }

    if (status === 400) {
      const message = error.response?.data?.error?.message;
      if (message === 'Redemption code expired') {
        store = await getStore();
        if (store) {
          setTimeout(() =>
            store?.dispatch(
              SET_REDEMPTION_CODE_EXPIRATION({ redemptionCodeExpirationTimestamp: 1 }),
            ),
          );
        }
      }
    }

    throw error;
  },
);

export { cmsAPI };
